import { debounceTime, filter, mergeMap, switchMap, tap } from "rxjs/operators";
import { DataFormComponent } from "src/common/components/data-form/data-form.component";
import {
    WorkflowReferenceFactory,
    CaseService,
    CountryService,
    ProgramCountryService,
    ProductService,
} from "src/services/program.services";
import {
    ProgramService,
    TeamService,
    WorkflowService,
    InquiryService,
} from "./../../../services/program.services";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SessionComponent } from "src/services/components/session.component";
import { ChangeDetectorRef, Component, Inject, ViewChild, inject } from "@angular/core";
import {
    APIListResult,
    APIObject,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
    ProgramReference,
} from "src/services/models/api-object";
import { Organization } from "src/services/models/organization";
import { Program, ProgramCountry } from "src/services/models/program";
import { BehaviorSubject, forkJoin, map, merge, Observable, of } from "rxjs";
import { DataForm } from "src/services/models/data";
import { ObjectViewMode } from "src/common/components/object.component";
import { Team, TeamMember, TeamMemberFactory } from "src/services/models/team";
import { Workflow } from "src/services/models/workflow";
import { HttpEvent } from "@angular/common/http";
import { Inquiry } from "src/services/models/inquiry";
import { Case } from "src/services/models/case";
import { CompoundDataTypeFactory, DataFormService } from "src/services/data.services";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { Country } from "src/services/models/country";
import { Product } from "src/services/models/product";
import { Hint } from "src/common/components/autocomplete-search/autocomplete-search.component";
import { RequestFilter } from "src/common/utilities/request";
import { OrganizationService } from "src/services/iam.services";

export type CreateRequestDialogData = {
    organizations?: ObjectReference[];
};

@Component({
    selector: "create-request",
    templateUrl: "./create-request.component.html",
    styleUrls: ["./create-request.component.scss"],
})
export class CreateRequestDialog extends SessionComponent {
    organizations: (ObjectReference | Organization)[] = [];
    programs: ObjectOrReference<Program>[] = [];
    formGroup: FormGroup;
    programService: ProgramService;
    dataFormService: DataFormService;
    teamService: TeamService;
    workflowService: WorkflowService;
    caseService: CaseService;
    inquiryService: InquiryService;
    intakeForm?: DataForm;
    staffTeam?: Team;
    workflows: Workflow[] = [];
    loading = false;
    countries: Country[] = [];
    @ViewChild(DataFormComponent) intakeFormComponent?: DataFormComponent;

    ObjectViewMode = ObjectViewMode;

    get createAsCase(): boolean {
        return !!this.formGroup.get("createAsCase")?.value;
    }
    get title(): string {
        return this.createAsCase ? "Create Case" : "Create Inquiry";
    }
    get primary(): TeamMember | undefined {
        return this.formGroup.get("primary")?.value;
    }
    get organization(): ObjectReference | Organization | undefined {
        return this.formGroup.get("organization")?.value;
    }
    countryService: CountryService;
    inquiryDetailsGroup: FormGroup;

    get countryControl() {
        return this?.inquiryDetailsGroup?.get("country") as FormControl<Country>;
    }
    get productControl() {
        return this.inquiryDetailsGroup?.get("product") as FormControl<Product>;
    }

    get programControl(): FormControl<Program> {
        return this?.inquiryDetailsGroup?.get("program") as FormControl<Program>;
    }
    programCountryService: ProgramCountryService;
    productService: ProductService;
    productHint: Hint = {
        text: "Select a product",
        condition: true,
        class: "hint",
    };
    organizationService: OrganizationService;
    constructor(
        @Inject(MAT_DIALOG_DATA) data: CreateRequestDialogData,
        protected formBuilder: FormBuilder,
        protected dialogRef: MatDialogRef<CreateRequestDialog>,
        private cdr: ChangeDetectorRef,
    ) {
        super();
        this.programService = inject(ProgramService);
        this.dataFormService = inject(DataFormService);
        this.teamService = inject(TeamService);
        this.workflowService = inject(WorkflowService);
        this.caseService = inject(CaseService);
        this.inquiryService = inject(InquiryService);
        this.countryService = inject(CountryService);
        this.productService = inject(ProductService);
        this.programCountryService = inject(ProgramCountryService);
        this.organizationService = inject(OrganizationService);
        this.countryService
            .getCountries()
            .subscribe((countries) => (this.countries = countries));

        inject(CompoundDataTypeFactory);
        inject(TeamMemberFactory);
        inject(WorkflowReferenceFactory);

        this.organizations = data.organizations ?? [];
        this.inquiryDetailsGroup = this.formBuilder.group({
            country: [null, Validators.required],
            product: [null, Validators.required],
            program: [null],
        });

        this.formGroup = this.formBuilder.group({
            organization: [null, Validators.required],
            createAsCase: [null],
            name: [null],
            workflow: [null],
            primary: [null],
            secondary: [null],
        });

        this.formGroup
            .get("organization")
            ?.valueChanges.subscribe((org: ObjectReference | Organization) => {
                this.updatePrograms(org);
                this.updateOrganizationStaff(org);
                this.updateWorkflows(org);
                this.getProductOptions().subscribe();
            });
        this.formGroup
            .get("name")
            ?.valueChanges.pipe(
                debounceTime(400),
                filter((name: string) => !!name),
                switchMap((name: string) =>
                    name ?
                        this.caseService.check_case_name_validity({
                            name: name,
                            organization: this.organization?.id,
                        })
                    :   of(undefined),
                ),
            )
            .subscribe((result?: HttpEvent<{ count: number }>) => {
                const { count } = result as any;
                if (count) this.formGroup.get("name")?.setErrors({ uniqueName: true });
                else this.formGroup.get("name")?.setErrors(null);
            });
        this.formGroup
            .get("createAsCase")
            ?.valueChanges.subscribe((isCase: boolean) => {
                if (isCase) {
                    this.formGroup.get("name")?.setValidators(Validators.required);
                    this.formGroup.get("workflow")?.setValidators(Validators.required);
                    this.formGroup.get("primary")?.setValidators(Validators.required);
                    if (this.programControl) {
                        const programChanged = this.programControl.valueChanges;
                        const countryChanged = this.countryControl.valueChanges;
                        merge(programChanged, countryChanged)
                            .pipe(
                                debounceTime(400),
                                tap(() => this.getProgramTeamMembers()),
                            )
                            .subscribe();
                    }
                } else {
                    this.formGroup.get("name")?.setValidators(null);
                    this.formGroup.get("workflow")?.setValidators(null);
                    this.formGroup.get("primary")?.setValidators(null);
                }
            });
        this.programControl?.valueChanges.subscribe(() => {
            this.updateWorkflows(
                this.organization,
                this.programControl?.value,
                this.countryControl.value,
            );
        });
        this.updateIntakeForm();
    }
    programCountry?: ProgramCountry;
    getProgramTeamMembers() {
        const country = this.countryControl?.value?.id;
        if (!this.programControl?.value?.id || !country) return;

        this.programCountryService
            .list({
                program: this.programControl.value.id,
                country: country,
                status: "open",
            })
            .subscribe((programCountries) => {
                programCountries = programCountries as ProgramCountry[];
                this.programCountry = programCountries[0] as ProgramCountry;
                this.updateOrganizationStaff();
            });
    }
    products: Product[] = [];
    getProgramOptions(): Observable<Program[]> {
        const organization =
            this.organizations?.length !== 1 ?
                this.formGroup.get("organization")?.value
            :   this.organizations[0];

        if (!organization?.id || !this?.currentAccount?.id) {
            return of([]);
        }

        const programs = this.programService
            .list({
                organization: organization.id,
                admin: this.currentAccount.id,
                use_reference: "True",
                deleted: "False",
                status: "Active",
            })
            .pipe(
                map((response) => response as Program[]),
                tap((programs) => {
                    this.programs = programs;
                    this.cdr.detectChanges();

                    if (this.programs.length === 1 && this.programControl) {
                        this.programControl.setValue(this.programs[0] as Program);
                    }
                }),
            );

        return programs;
    }
    getProductOptions() {
        const organization =
            this.organizations?.length !== 1 ?
                this.formGroup.get("organization")?.value
            :   this.organizations[0];

        if (!organization?.id) return of([]);
        const products = this.productService.list({
            owner: organization.id,
            published: "True",
        });

        return products.pipe(
            map((results: APIListResult<Product>) => results as Product[]),
            tap((products) => {
                this.products = [...new Set(products)] as Product[];

                this.cdr.detectChanges();

                if (this.products.length === 1) {
                    this.productControl.setValue(this.products[0]);
                }
            }),
        );
    }
    orgChange(e: MatSelectChange) {
        const org = e.value;
        this.updatePrograms(org);
        this.updateOrganizationStaff(org);
        this.updateWorkflows(org);

        this.formGroup.controls["organization"].setValue(org);
    }
    get intakeFormGroup() {
        return this.intakeFormComponent?.formGroup;
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.formGroup.setValue({
            createAsCase: false,
            organization: this.organizations.length ? this.organizations[0] : null,
            name: null,
            workflow: null,
            primary: null,
            secondary: null,
        });
        this.getProductOptions().subscribe();

        merge(
            this.countryControl.valueChanges,
            this.productControl.valueChanges,
            this.programControl.valueChanges,
        )
            .pipe(
                debounceTime(500),
                tap(() => {
                    // need to check every time a value changes since they are dependent on each other
                    if (this?.countryControl?.value) {
                        this.isCountryValid();
                    }

                    if (this?.productControl?.value) {
                        this.isProductValid();
                    }

                    if (this?.programControl?.value) {
                        this.isProgramCountryValid();
                    }
                }),
            )
            .subscribe();

        this.initializeCurrentAccountCountryPermissions();
        this.initializeCurrentAccountProgramPermissions();
    }
    create(event?: MouseEvent): void {
        const organization = this.formGroup.value["organization"];

        let intakeFormData: { [key: string]: any } = {};

        intakeFormData = this.intakeFormComponent?.formGroup.value || {};
        for (let key in intakeFormData) {
            if (intakeFormData.hasOwnProperty(key)) {
                const value = intakeFormData[key];
                if (value instanceof APIObject)
                    intakeFormData[key] = value.type + ":" + value.id;
            }
        }

        const inquiry_data = {
            "request.source": "in-app",
            "request.role": "internal",
            organization: organization.asReference.serialize(),
            "request.country": this.countryControl.value.asReference.serialize(),
            "request.product": this.productControl.value.asReference.serialize(),
            "request.program":
                this.programControl?.value ?
                    this.programControl.value.asReference.serialize()
                :   undefined,
            intakeForm: this.intakeForm?.asReference.serialize(),
            data: intakeFormData,
        };
        this.loading = true;
        this.inquiryService
            .intake(inquiry_data)
            .pipe(
                map((result: any) => ObjectFactory.makeObject<Inquiry>(result)),
                mergeMap((o: ObjectOrReference<Inquiry>) =>
                    ObjectFactory.objectObservable(o),
                ),
                mergeMap((inquiry: Inquiry | undefined) => {
                    if (inquiry && this.createAsCase) {
                        const new_case = ObjectFactory.makeObject<Case>(
                            {
                                owner: organization.asReference.serialize(),
                                shared: inquiry,
                                name: this.formGroup.value["name"],
                                workflow:
                                    this.formGroup.value[
                                        "workflow"
                                    ].asReference.serialize(),
                                primary:
                                    this.formGroup.value[
                                        "primary"
                                    ].asReference.serialize(),
                                secondary:
                                    this.formGroup.value["secondary"]?.map(
                                        (tm: TeamMember) => tm.asReference.serialize(),
                                    ) || [],
                            },
                            Case.object_type,
                        );
                        return this.caseService.create(new_case);
                    }
                    return of(inquiry);
                }),
            )
            .subscribe({
                next: (c: Case | Inquiry | undefined) => {
                    this.dialogRef.close(c);
                    this.loading = false;
                },
                error: (err: any) => {
                    this.loading = false;
                    this.dialogRef.close();
                    console.error(err);
                    const message =
                        "Unable to create " +
                        (this.createAsCase ? "case" : "inquiry") +
                        ". Please contact the system administrator.";
                    this.snackbar.open(message, undefined, { duration: 2000 });
                },
            });
    }
    hasError(field: string, error: string): boolean {
        return this.formGroup.get(field)?.hasError(error) ?? false;
    }

    protected updatePrograms(org?: Organization | ObjectReference): void {
        if (org?.id && this.currentAccount?.id) {
            this.programService
                .list({
                    organization: org.id,
                    use_reference: "True",
                    deleted: "False",
                    status: "Active",
                })
                .pipe(
                    map(
                        (results: APIListResult<ProgramReference>) =>
                            results as ProgramReference[],
                    ),
                )
                .subscribe((programs: ProgramReference[]) => {
                    this.programs = programs;
                });
        } else {
            this.programs = [];
        }
    }
    protected updateIntakeForm(): void {
        this.dataFormService
            .list({ name: "exact:intake.internal", is_template: "True" })
            .subscribe((forms: APIListResult<DataForm>) => {
                if (forms && (forms as DataForm[]).length) {
                    this.intakeForm = (forms as DataForm[])[0];
                } else {
                    this.intakeForm = undefined;
                }
            });
    }
    teams: Team[] = [];
    protected updateOrganizationStaff(org?: Organization | ObjectReference): void {
        const organization =
            this.organizations?.length !== 1 ?
                this.formGroup.get("organization")?.value
            :   this.organizations[0];

        const teams = [organization.id];
        if (this.programControl?.value?.id) {
            teams.push(this.programControl.value.id);
        }

        const programCountryTeam =
            this?.programCountry?.id ?
                this.teamService.list({
                    owned: this?.programCountry?.id,
                    program_status: "Active",
                    program_deleted: "False",
                    program_country_status: "open",
                })
            :   of([]);

        const orgAndProgramTeams = this.teamService.list({
            owned: teams.join(","),
            type: "staff",
        });

        forkJoin({ orgAndProgramTeams, programCountryTeam })
            .pipe(
                tap(({ orgAndProgramTeams, programCountryTeam }) => {
                    this.teams = [
                        ...(orgAndProgramTeams as Team[]),
                        ...(programCountryTeam as Team[]),
                    ];

                    const org = orgAndProgramTeams as Team[];
                    this.staffTeam = org.find(
                        (t) =>
                            t?.organization?.id === organization.id ||
                            t.owner?.id === organization.id,
                    );

                    this.updatePrimary();
                }),
            )
            .subscribe();
    }
    get avaialavlePrimaryAssignees() {
        const uniqueMembers: { [key: string]: TeamMember } = {};
        this.teams?.forEach((team) => {
            team.members!.forEach((member) => {
                if (member?.account?.id && !uniqueMembers[member?.account?.id]) {
                    uniqueMembers[member.account.id] = member;
                }
            });
        });

        return Object.values(uniqueMembers);
    }
    get availableSecondaryAssignees() {
        const primaryAssignee = this.formGroup.get("primary")?.value;
        return this.avaialavlePrimaryAssignees.filter(
            (member) => member.account.id !== primaryAssignee?.account?.id,
        );
    }
    protected updatePrimary(): void {
        const owner = this.currentAccount;
        const primary = this.staffTeam?.members?.find(
            (tm: TeamMember) => tm.account.id == owner?.id,
        );
        this.formGroup.controls.primary.setValue(primary);
    }
    protected updateWorkflows(
        org?: ObjectOrReference<Organization>,
        program?: ObjectOrReference<Program>,
        country?: ObjectOrReference<Country>,
    ): void {
        let $obs: Observable<Organization | undefined>;

        if (org instanceof Organization) {
            $obs = of(org);
        } else {
            $obs = this.organizationService.resolveReference(org);
        }

        $obs.pipe(
            switchMap((resolvedOrg) => {
                const allowSystemWorkflows = !resolvedOrg?.hideSystemWorkflows;
                const owners = [];
                if (allowSystemWorkflows) owners.push("0");
                if (resolvedOrg?.id) owners.push(resolvedOrg.id);
                if (program?.id) owners.push(program.id);
                if (country?.id) owners.push(country.id);
                const filters: RequestFilter = {
                    owned: owners.join(","),
                    subworkflow: "False",
                    version: "1",
                };
                return this.workflowService.list(filters);
            }),
        ).subscribe((workflows: APIListResult<Workflow>) => {
            this.workflows = workflows as Workflow[];
            if (this.workflows.length === 1) {
                this.formGroup.get("workflow")?.setValue(this.workflows[0].id);
            }
        });
    }
    updateValidity() {
        if (this.createAsCase) {
            this.formGroup.controls.name.enable();
            this.formGroup.controls.workflow.enable();
            this.formGroup.controls.primary.enable();
        } else {
            this.formGroup.controls.name.disable();
            this.formGroup.controls.workflow.disable();
            this.formGroup.controls.primary.disable();
        }
    }

    handleCountryFlagError(country: Country) {
        if (country instanceof Country) {
            country.flag_url = this.fallbackFlagUrl;
        }
    }
    get fallbackFlagUrl(): string {
        return this.countryService.fallbackFlagUrl;
    }
    get countryInvalidToolTip() {
        return "This country has not been defined in the system for any program as approved.";
    }
    createCountryValid = true;
    isCountryValid() {
        if (!this.organization?.id) return;
        this.organizationService
            .isCountryValid(this.organization, this.countryControl?.value)
            .subscribe((valid) => {
                this.createCountryValid = valid;
            });
    }
    createProductValid = true;
    isProductValid() {
        this.inquiryService
            .isProductValid(
                this.productControl?.value,
                this.countryControl?.value,
                this.programControl?.value,
                this.organization,
            )
            .subscribe((valid) => {
                this.createProductValid = valid;
            });
    }
    get productInvalidToolTip() {
        return Product.invalidProductCaseToolTip;
    }
    get programCountryInvalidToolTip() {
        return "The selected country and program combination is not explicitly configured.";
    }
    createProgramCountryValid = true;
    isProgramCountryValid() {
        if (!this.programControl?.value || !this.countryControl?.value) return;

        this.inquiryService
            .isProgramCountryValid(
                this.countryControl?.value,
                this.programControl?.value,
            )
            .subscribe((valid) => {
                this.createProgramCountryValid = valid;
            });
    }
    get currentAccountManagesOrg(): boolean {
        if (this.organization) {
            return (
                !!this.currentAccount?.hasDerivedPermission(
                    "object.admin",
                    this.organization,
                ) ||
                !!this.currentAccount?.hasDerivedPermission(
                    "object.manager",
                    this.organization,
                )
            );
        } else return false;
    }
    get currentAccountManagesAnOrgProgram(): boolean {
        if (this.organization) {
            return !!this.currentAccount?.derived_permissions?.some(
                (p) =>
                    (p.permission === "object.admin" ||
                        p.permission === "object.manager") &&
                    p.object_type === "program.program" &&
                    p.root_organization.id === this.organization!.id,
            );
        } else return false;
    }
    currentAccountHasCountryPermission(country_id: string): boolean {
        if (!this.currentAccountCountryPermissions.hasLimitedAccess) return true;
        return this.currentAccountCountryPermissions.limitedCountryIds.includes(
            country_id,
        );
    }
    currentAccountHasProgramPermission(program_id: string): boolean {
        if (!this.currentAccountProgramPermissions.hasLimitedAccess) return true;
        return this.currentAccountProgramPermissions.limitedProgramIds.includes(
            program_id,
        );
    }
    currentAccountHasProductPermission(product_id: string): boolean {
        if (!this.currentAccountProductPermissions.hasLimitedAccess) return true;
        return this.currentAccountProductPermissions.limitedProductIds.includes(
            product_id,
        );
    }
    private currentAccountCountryPermissions: {
        hasLimitedAccess: boolean;
        limitedCountryIds: string[];
    } = {
        hasLimitedAccess: false,
        limitedCountryIds: [],
    };
    private initializeCurrentAccountCountryPermissions() {
        if (this.currentAccountManagesOrg || this.currentAccountManagesAnOrgProgram) {
            this.currentAccountCountryPermissions.hasLimitedAccess = false;
            this.currentAccountCountryPermissions.limitedCountryIds = [];
        } else {
            this.currentAccountCountryPermissions.hasLimitedAccess = true;
            const explictProgramCountryIds = this.currentAccount?.derived_permissions
                .filter(
                    (p) =>
                        (p.permission === "object.admin" ||
                            p.permission === "object.manager") &&
                        p.permission_type === "explicit" &&
                        p.object_type === "program.programcountry",
                )
                .map((p) => p.object_id);

            if (explictProgramCountryIds?.length) {
                this.programCountryService
                    .list({
                        id: explictProgramCountryIds.join(","),
                    })
                    .pipe(
                        map((programCountries) => programCountries as ProgramCountry[]),
                        map((programCountries) =>
                            programCountries.map((pc) => pc.country.id),
                        ),
                    )
                    .subscribe((countryIds) => {
                        this.currentAccountCountryPermissions.limitedCountryIds =
                            countryIds.filter((id): id is string => id !== undefined);
                    });
            }
        }
    }
    private currentAccountProgramPermissions: {
        hasLimitedAccess: boolean;
        limitedProgramIds: string[];
    } = {
        hasLimitedAccess: false,
        limitedProgramIds: [],
    };
    private initializeCurrentAccountProgramPermissions() {
        if (this.currentAccountManagesOrg) {
            this.currentAccountProgramPermissions.hasLimitedAccess = false;
            this.currentAccountProgramPermissions.limitedProgramIds = [];
        } else {
            this.currentAccountProgramPermissions.hasLimitedAccess = true;
            const allowedProgramIds = this.currentAccount?.derived_permissions
                .filter(
                    (p) =>
                        (p.permission === "object.admin" ||
                            p.permission === "object.manager" ||
                            p.permission === "descendant.object.admin" ||
                            p.permission === "descendant.object.manager") &&
                        p.object_type === "program.program",
                )
                .map((p) => p.object_id);

            if (allowedProgramIds?.length) {
                this.currentAccountProgramPermissions.limitedProgramIds =
                    allowedProgramIds.filter((id): id is string => id !== undefined);
            }
        }
        this.initializeCurrentAccountProductPermissions();
    }
    private currentAccountProductPermissions: {
        hasLimitedAccess: boolean;
        limitedProductIds: string[];
    } = {
        hasLimitedAccess: false,
        limitedProductIds: [],
    };
    private initializeCurrentAccountProductPermissions() {
        if (this.currentAccountManagesOrg) {
            this.currentAccountProductPermissions.hasLimitedAccess = false;
            this.currentAccountProductPermissions.limitedProductIds = [];
        } else {
            this.currentAccountProductPermissions.hasLimitedAccess = true;
            if (this.currentAccountProgramPermissions.limitedProgramIds?.length) {
                this.productService
                    .list({
                        program:
                            this.currentAccountProgramPermissions.limitedProgramIds.join(
                                ",",
                            ),
                    })
                    .pipe(
                        map((products) => products as Product[]),
                        map((products) => products.map((p) => p.id)),
                    )
                    .subscribe((productIds) => {
                        this.currentAccountProductPermissions.limitedProductIds =
                            productIds.filter((id): id is string => id !== undefined);
                    });
            }
        }
    }
}
