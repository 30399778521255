import { Component, inject, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { map, Observable, of } from "rxjs";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { AccountService, CapabilityService } from "src/services/iam.services";
import { Account } from "src/services/models/account";
import { ObjectFactory } from "src/services/models/api-object";
import { Capability } from "src/services/models/capability";
import { Organization } from "src/services/models/organization";
import { Role, RoleDefinition } from "src/services/models/role";
import { Team, TeamMember } from "src/services/models/team";
import { TeamMemberService } from "src/services/program.services";

@Component({
    selector: "staff-permission",
    templateUrl: "./staff-permission.component.html",
    styleUrls: ["./staff.component.scss"],
})
export class StaffPermissionComponent
    extends ObjectComponent<TeamMember>
    implements OnInit
{
    @Input() capabilities?: Capability[] = [];
    capabilitiesList$!: Observable<Capability[]>;
    private capabilitiesService = inject(CapabilityService);

    get availableRoles(): RoleDefinition[] {
        return Role.roles.filter((role) => role.type === "object");
    }
    get publicCheckboxToolTip() {
        return "By making this user public they will be visible to external users. If you want this user to have tasks assigned to them or have messages sent to them, leave this box checked.";
    }
    get isCurrentAccount(): boolean {
        return this.fullObject?.account?.id == this.currentAccount?.id;
    }
    get isSysAdminAccount(): boolean {
        if (this.fullObject?.account instanceof Account) {
            return this.fullObject.account.isSystemAdministrator;
        }
        return false;
    }

    constructor(protected service: TeamMemberService) {
        super(service);
    }

    ngOnInit(): void {
        if (this.capabilities?.length) {
            this.capabilitiesList$ = of(this.capabilities);
        } else {
            this.capabilitiesList$ = this.getCapabilitiesList$();
        }
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            team: [null, Validators.required],
            account: [null, Validators.required],
            permission: [null, Validators.required],
            private: [null, Validators.required],
            role: [null],
            capabilities: [null],
        });
    }

    protected setObject(v?: TeamMember): void {
        super.setObject(v);

        if (this.mode == ObjectViewMode.Create) {
            this.objectName = "Add Staff";
            this.formGroup.get("role")?.setValue("member");
        } else if (this.mode == ObjectViewMode.Edit) {
            this.objectName = "Edit Team Permission";
            this.formGroup
                .get("capabilities")!
                .setValue(this.formGroup.get("capabilities")!.value[0]);

            this.formGroup
                .get("role")!
                .setValue(this.formGroup.get("role")!.value ?? "member");

            const perm = this.availableRoles.find(
                (r) => r.value == this.formGroup.get("permission")!.value.role,
            );
            this.formGroup.get("permission")!.setValue(perm);
            if (
                (this.isCurrentAccount || this.isSysAdminAccount) &&
                !this.currentAccount?.isSystemAdministrator
            ) {
                this.formGroup.get("permission")!.disable();
            }
        } else {
            this.objectName = "View " + (this.object?.displayName ?? "Permission");
        }
    }

    private wrapInArray<T>(value: T | T[]): T[] {
        return Array.isArray(value) ? value : [value];
    }

    onSave(): void {
        const capabilities = this.formGroup.get("capabilities")?.value;
        if (!!capabilities) {
            this.formGroup
                .get("capabilities")
                ?.setValue(this.wrapInArray(capabilities));
        }
        const permission = ObjectFactory.makeObject<Role>(
            {
                id: this.fullObject?.permission?.id,
                object: this.fullObject?.permission?.object,
                role: this.formGroup.get("permission")!.value.value,
                account: this.fullObject?.account,
            },
            Role.object_type,
        );
        this.formGroup.get("permission")?.setValue(permission);
        super.onSave();
    }

    protected getCapabilitiesList$(): Observable<Capability[]> {
        const owners: string[] = [];
        if (this.fullObject?.team instanceof Team) {
            const org = this?.fullObject?.team?.organization as Organization;
            org?.id && owners.push(org?.id);

            if (!org.hideSystemRoles) {
                owners.push("0");
            }
        }
        return this.capabilitiesService.list({ org: owners.join(",") }).pipe(
            map((capabilities) => capabilities as Capability[]),
            map((capabilities: Capability[]) =>
                capabilities.slice().sort((a, b) => {
                    const aName = a.display_name ?? a.name ?? "";
                    const bName = b.display_name ?? b.name ?? "";
                    return aName.localeCompare(bName);
                }),
            ),
        );
    }
}
