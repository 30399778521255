import { transition } from "@angular/animations";
import { ChangeDetectorRef, Component, Inject, ViewChild, inject } from "@angular/core";
import {
    Document,
    DocumentOwner,
    DocumentRepository,
    DocumentTypeDefinition,
} from "../../../services/models/document";
import {
    FormControl,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
} from "@angular/forms";
import { FileItem, FileUploaderOptions } from "ng2-file-upload";
import { DocumentFileItem, DocumentService } from "../../../services/program.services";
import { forkJoin, from, Observable, of } from "rxjs";
import { map, mergeMap, tap, finalize } from "rxjs/operators";
import { ConfirmDialog } from "../../../common/components/confirm.dialog";
import {
    ObjectComponent,
    ObjectViewMode,
} from "../../../common/components/object.component";
import { Inquiry } from "../../../services/models/inquiry";
import { PDFDocumentProxy, PDFPageProxy } from "ng2-pdf-viewer";
import { DocusignTabsDialog } from "../../../integration/docusign/tabs/docusign-tabs.dialog";
import { Assignment } from "src/services/models/assignment";
import { CompleteTodoComponent } from "./complete-todo.component";
import { Case } from "src/services/models/case";
import { DomSanitizer } from "@angular/platform-browser";
import { Organization } from "src/services/models/organization";
import { FileUploaderCustom } from "src/common/utilities/FileUploaderCustom";
import { CASE_TAB_NAMES, TabChangeService } from "src/services/component.services";
import {
    APIListResult,
    ObjectOrReference,
    OptionalObjectOrReference,
} from "src/services/models/api-object";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatCheckboxChange } from "@angular/material/checkbox";

type Point = { x: number; y: number };
type Size = { width: number; height: number };
type BoundingBox = { origin: Point; size: Size };

@Component({
    selector: "document",
    templateUrl: "./document.component.html",
    styleUrls: ["./document.component.scss"],
})
export class DocumentComponent extends ObjectComponent<Document> {
    availableDocuments: Document[] = [];
    uploader?: FileUploaderCustom;
    fileOver: boolean = false;
    existingControl: UntypedFormControl;
    objectName = "Upload Files";
    editing?: DocumentFileItem;
    bulkMode: boolean = false;
    sources: DocumentRepository[] = [];
    owner?: DocumentOwner;
    repository?: DocumentRepository;
    private _repositoryBackup?: DocumentRepository;

    redacting: number = 0;
    loading: boolean = false;
    uploadedDocuments: Document[] = [];
    phiDocuments: Document[] = [];
    phiDocumentsTotal: number = 0;
    originalAttributes: any = {};

    contextMenuPosition: Point = { x: 0, y: 0 };
    @ViewChild(MatMenuTrigger) contextMenuTrigger?: MatMenuTrigger;
    /**
     * Should be used as id string for <input type=file />
     */
    elementId: string = Date.now().toString();

    assignment?: Assignment;
    casesForBulk?: (Case | Inquiry)[];
    pxOffset = 16; // equal to 1rem

    tabChangeService: TabChangeService;
    constructor(
        protected service: DocumentService,
        protected sanitizer: DomSanitizer,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service);
        this.existingControl = new UntypedFormControl();
        this.tabChangeService = inject(TabChangeService);
    }

    get isCaseRepository(): boolean {
        return (
            this.repository?.type == "program.case" ||
            this.repository?.type == "program.inquiry"
        );
    }
    get showShareOption() {
        return (
            this.repository?.type === "program.case" ||
            this._repositoryBackup?.type === "program.case" ||
            this.bulkMode
        );
    }
    get shareOptionText() {
        return this.isPhysician ?
                "Do you want to share this with the pharmaceutical company?"
            :   "Do you want to share this with the HCP team?";
    }

    shareDocuments(e: MatCheckboxChange) {
        const { checked } = e || {};

        const fileItems = this.uploader?.queue;
        if (this.repository?.type === "program.case" && checked) {
            this._repositoryBackup = this.repository;
            this.initializeUploader(
                this.owner!,
                (this.repository as Case)?.shared,
                this.sources,
                fileItems,
            );
        } else if (!checked && this._repositoryBackup?.type === "program.case") {
            this.initializeUploader(
                this.owner!,
                this._repositoryBackup,
                this.sources,
                fileItems,
            );
        }
    }
    get queue(): DocumentFileItem[] | undefined {
        return this.uploader?.queue as DocumentFileItem[];
    }
    get existing(): boolean {
        return !!this.existingControl.value;
    }
    get checkedDocuments(): Document[] {
        return this.formGroup.value.copy
            .map((checked: boolean, i: number) =>
                checked ? this.availableDocuments[i] : null,
            )
            .filter((v: Document[]) => v !== null);
    }
    get isValid(): boolean {
        return super.isValid && !!this.checkedDocuments.length && !this.editing;
    }

    get isPhysician(): boolean {
        return (
            this.isCaseRepository &&
            (this.repository as Case | Inquiry).isPhysicianStaff(this.currentAccount)
        );
    }
    get walkthroughEnabled(): boolean {
        return this.isPhysician && !this.completedWalkthrough;
    }
    get redactionsEnabled() {
        if (this.owner instanceof Organization) {
            const tempDisabled = !!this.owner.setting("redaction", "disabled");
            return this.owner.entitlements?.redaction?.enabled && !tempDisabled;
        } else if (
            this.bulkMode &&
            this.uploader?.options?.additionalParameter?.redactionEnabledOrg
        ) {
            return true;
        }
        return false;
    }

    get repoIsShared(): boolean {
        return !!this.shareCheckbox.value;
    }

    initializeUploader(
        owner: DocumentOwner,
        repository: DocumentRepository,
        sources: DocumentRepository[],
        fileItems?: FileItem[],
        options?: FileUploaderOptions,
    ): void {
        const isFirstInitialization = !this.repository;
        this.repository = repository;
        if (
            isFirstInitialization &&
            this.showShareOption &&
            (repository as Case)?.shared &&
            this.isPhysician
        ) {
            //by default sharing option is enabled for hcp users but pharma users default to internal
            this._repositoryBackup = repository;
            this.repository = (repository as Case)?.shared;
            this.shareCheckbox.patchValue(true);
        }
        this.sources = sources.filter(
            (s: DocumentRepository) => s.id !== this.repository?.id,
        );
        this.owner = owner;

        if (isFirstInitialization) {
            this.updateAvailableDocuments(this.sources);
        }

        this.service
            .fileUploader(
                this.owner,
                this.repository,
                this.session.currentAccount!,
                (docs: Document[]) => {
                    this.uploadedDocuments.push(...docs);
                },
            )
            .subscribe((uploader?: FileUploaderCustom) => {
                this.uploader = uploader;
                if (options) {
                    this.uploader?.setOptions(options);
                }
                if (fileItems?.length) {
                    this.uploader?.addToQueue(fileItems.map((f: FileItem) => f._file));
                }
            });
        this.elementId = Date.now.toString();
        if (this.repoIsShared) {
            this.objectName =
                this.objectName.includes(":") ?
                    this.objectName.replace("Upload", "Share")
                :   "Share Files";
        } else {
            this.objectName =
                this.objectName.includes(":") ?
                    this.objectName.replace("Share", "Upload")
                :   "Upload Files";
        }
    }

    shareCheckbox = new FormControl(false);
    isUploading: boolean = false;
    uploadFiles() {
        if (this.queue && this.uploader) {
            this.loading = true;
            this.isUploading = true;
            const originalOnCompleteAll = this.uploader.onCompleteAll.bind(
                this.uploader,
            );
            this.uploader.onCompleteAll = () => {
                originalOnCompleteAll();
                this.isUploading = false;
                this.checkForPHI(this.uploadedDocuments);
            };

            this.uploader.uploadAllOneRequest();
        }
    }

    removeQueueItem(event: MouseEvent, queueItem: FileItem): void {
        this.terminateEvent(event);
        if (this.editing == queueItem) this.editing = undefined;
        queueItem.remove();
    }
    uploadDocuments(): void {
        this.isUploading = true;
        this.uploadFiles();
    }
    copyDocuments(): void {
        if (this.repository && !!this.session.currentAccount) {
            this.loading = true;
            this.service
                .copyDocuments(
                    this.checkedDocuments,
                    this.repository,
                    this.session.currentAccount,
                )
                .subscribe({
                    next: (docs: ObjectOrReference<Document>[]) => {
                        this.loading = false;
                        if (docs.length) {
                            this.onAfterDone();
                            if (this.bulkMode) this.handleBulkUpload(docs);
                        }
                    },
                    error: () => {
                        this.loading = false;
                    },
                });
        }
    }
    patients: (Case | Inquiry)[] = [];
    handleBulkUpload(documents: ObjectOrReference<Document>[]): void {
        const share = this.repoIsShared;
        const bulkObs = this.patients.map((o) => {
            if (o instanceof Case) {
                return this.service.copyDocuments(documents, share ? o.shared : o);
            }
            return this.service.copyDocuments(documents, o);
        });

        const cleanupObs = this.getBulkCleanUpObs(documents);
        forkJoin(bulkObs).subscribe({
            next: (v) => {
                this.loading = false;
                if (cleanupObs) forkJoin(cleanupObs).subscribe();
                this.onAfterDone();
            },
            error: () => {
                this.loading = false;
                if (cleanupObs) forkJoin(cleanupObs).subscribe();
                this.dialogReference?.close();
            },
            complete: () => {
                this.loading = false;
                if (cleanupObs) forkJoin(cleanupObs).subscribe();
                this.onAfterDone();
            },
        });
    }
    getBulkCleanUpObs(
        documents: ObjectOrReference<Document>[],
    ): Observable<OptionalObjectOrReference<Document>>[] {
        //when redaction is enabled, the repository for bulk upload is the current account to prevent the unredacted documents from getting uploaded to a case, since AI Redaction needs the document to be uploaded. But after it gets copied to all the cases, the document uploaded under the account is never necessary
        const cleanupObs =
            this.phiDocumentsTotal ?
                documents
                    .filter((d: ObjectOrReference<Document>) => d instanceof Document)
                    .map((d: ObjectOrReference<Document>) => d as Document)
                    .map((d: Document) => {
                        if (d.repository?.type === "iam.account") {
                            return this.service.destroy(d);
                        }
                        return of(undefined);
                    })
            :   [];
        return cleanupObs;
    }
    handleCancel(e?: MouseEvent) {
        if (e) this.terminateEvent(e);
        if (!this.bulkMode) return this.dialogReference?.close();

        const obs =
            this.bulkMode ? this.getBulkCleanUpObs(this.uploadedDocuments) : undefined;

        forkJoin(obs ?? []).subscribe((v) => {
            this.dialogReference?.close();
        });
    }
    documentTypeDisplay(type: string): string {
        const documentType = Document.documentTypes.find(
            (dt: DocumentTypeDefinition) => dt.type == type,
        );
        return documentType?.displayName ?? "Unknown Type";
    }
    documentTypeGroups(): (string | undefined)[] {
        return Document.documentTypes
            .map((dt: DocumentTypeDefinition) => dt.group)
            .filter(
                (
                    value: string | undefined,
                    index: number,
                    list: (string | undefined)[],
                ) => list.indexOf(value) === index,
            );
    }
    documentTypesForGroup(group: string | undefined): DocumentTypeDefinition[] {
        return Document.documentTypes
            .filter((dt: DocumentTypeDefinition) => dt.group == group)
            .sort((a: DocumentTypeDefinition, b: DocumentTypeDefinition) => {
                if (a.displayName < b.displayName) return -1;
                if (a.displayName > b.displayName) return 1;
                return 0;
            });
    }

    zoom: number = 1.0;
    page_: number = 1;
    pagesVisited: Set<number> = new Set<number>();
    get page(): number {
        return this.page_;
    }
    set page(v: number) {
        this.page_ = v;
        this.pdf?.getPage(v).then((p: PDFPageProxy) => {
            this.pagesVisited.add(v);
            this.currentPage = p;
        });
    }
    get allPagesVisited(): boolean {
        return this.pagesVisited.size == this.totalPages;
    }
    currentPage?: PDFPageProxy;
    pdf?: PDFDocumentProxy;
    documentContents?: Uint8Array;
    image: any;
    redactionThreshold: number = 0.9;
    get imageWidth(): number {
        return this.isPDF ?
                this.viewportWidth
            :   this.fullObject?.attributes?.width || 0;
    }
    get imageHeight(): number {
        return this.isPDF ?
                this.viewportHeight
            :   this.fullObject?.attributes?.height || 0;
    }

    get viewportWidth(): number {
        const width = this.currentPage?.getViewport({ scale: this.zoom }).width ?? 100;
        return width * DocusignTabsDialog.PDFRatio;
    }
    get viewportHeight(): number {
        const height =
            this.currentPage?.getViewport({ scale: this.zoom }).height ?? 100;
        return height * DocusignTabsDialog.PDFRatio;
    }
    get pageWidth(): string {
        return this.imageWidth.toString() + "px";
    }
    get pageHeight(): string {
        return this.imageHeight.toString() + "px";
    }
    get pageArray(): number[] {
        return [...Array(this.totalPages).keys()];
    }
    get totalPages(): number {
        return this.pdf?.numPages ?? 0;
    }
    firstPage(): void {
        this.page = 1;
    }
    lastPage(): void {
        this.page = this.totalPages;
    }
    nextPage(): void {
        this.page = this.page < this.totalPages ? this.page + 1 : this.page;
    }
    previousPage(): void {
        this.page = this.page > 1 ? this.page - 1 : this.page;
    }
    onPDFLoaded(pdf: PDFDocumentProxy): void {
        this.pdf = pdf;
        this.page = this.page; // NOSONAR
    }
    get isPDF(): boolean {
        return this.fullObject?.file_format == "application/pdf";
    }

    protected setObject(v?: Document) {
        super.setObject(v);
        this.documentContents = undefined;
        if (this.fullObject && this.mode != ObjectViewMode.Create) {
            this.session
                .downloadBlob(this.fullObject.file, this.fullObject.name)
                .pipe(
                    tap(() => (this.loading = true)),
                    mergeMap((blob: Blob) => {
                        if (this.fullObject?.file_format?.startsWith("image/")) {
                            const reader = new FileReader();
                            reader.onload = (event: ProgressEvent<any>) => {
                                this.image = this.sanitizer.bypassSecurityTrustUrl(
                                    event.target.result,
                                );
                                this.changeDetection.detectChanges();
                            };
                            reader.readAsDataURL(blob);
                        } else {
                            this.image = undefined;
                        }
                        return from(blob.arrayBuffer());
                    }),
                    finalize(() => {
                        this.loading = false;
                        this.changeDetection.detectChanges();
                    }),
                )
                .subscribe((buffer: ArrayBuffer) => {
                    this.loading = false;
                    this.documentContents = new Uint8Array(buffer);
                });
        }

        const attributes = JSON.stringify(v?.attributes);
        this.originalAttributes = JSON.parse(attributes);
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            copy: this.formBuilder.array([]),
        });
    }

    protected updateAvailableDocuments(sources: DocumentRepository[]): void {
        const obs = sources.map((repo: DocumentRepository) =>
            this.service.list({ repo: repo.id! }),
        );
        forkJoin(obs)
            .pipe(
                map((res: APIListResult<Document>[]) =>
                    ([] as Document[]).concat(...(res as Document[][])),
                ),
                tap((documents: Document[]) => this.updateCheckboxes(documents)),
            )
            .subscribe((documents: Document[]) => {
                this.availableDocuments = documents;
            });
    }
    protected updateCheckboxes(documents: Document[]): void {
        const array: UntypedFormArray = this.asFormArray(this.formGroup.controls.copy);
        while (array.length !== 0) array.removeAt(0);
        documents.forEach(() => array.push(new UntypedFormControl(false)));
    }

    get redactionProcessing(): boolean {
        return this.fullObject?.attributes?.redaction_processing;
    }
    get needsRedaction(): boolean {
        return this.hasAutomatedRedactions;
    }
    get isInternalUser(): boolean {
        if (this.repository instanceof Inquiry || this.repository instanceof Case)
            return this.repository.isPharmaStaff(this.currentAccount);
        return false;
    }
    get hideRedactionWarnings(): boolean {
        let hideExternal = true;
        let hideInternal = true;

        if (this.fullObject && this.fullObject.owner instanceof Organization) {
            hideExternal =
                !!this.fullObject.owner.settings?.settings?.redaction?.hideExternal;
            hideInternal =
                !!this.fullObject.owner.settings?.settings?.redaction.hideInternal;
        }
        return this.isInternalUser ? hideInternal : hideExternal;
    }
    get allRedactions(): any[] {
        return this.fullObject?.attributes?.detected_phi || [];
    }
    pageRedactions(page: number): any {
        const page_entities = this.allRedactions.filter(
            (entity: any) => entity?.page == page - 1,
        );
        return page_entities;
    }
    get currentRedactions(): any[] {
        return this.isPDF ? this.pageRedactions(this.page) : this.allRedactions;
    }
    get hasAutomatedRedactions(): boolean {
        return this.allRedactions.filter((r: any) => !r.added).length > 0;
    }
    redactionInfo(redaction: any): string {
        return redaction?.added ? "Type: MANUAL" : (
                "Type: " +
                    redaction.type +
                    "\n" +
                    "PHI Probability: " +
                    this.formatThreshold(redaction.score)
            );
    }
    formatThreshold(value: number): string {
        return (value * 100).toFixed(2) + "%";
    }
    toggleRedaction(redaction: any): void {
        if (this.mode == ObjectViewMode.Edit) {
            if (redaction.added && this.fullObject) {
                this.fullObject.attributes.detected_phi =
                    this.fullObject.attributes.detected_phi.filter(
                        (r: any) => r !== redaction,
                    ); // remove the redaction
            } else redaction.visible = !this.redactionIsVisible(redaction);
        }
    }
    redactionIsVisible(redaction: any): boolean {
        return (
            redaction.visible ||
            (redaction.score < this.redactionThreshold && !("visible" in redaction))
        );
    }
    acceptRedactions(): void {
        let obs: Observable<boolean>;
        if (
            this.originalAttributes?.detected_phi?.length &&
            this.pdf &&
            !this.allPagesVisited
        ) {
            const visitedCount = this.pagesVisited.size;
            const totalPages =
                this.totalPages != 1 ? "" + this.totalPages + " pages" : "1 page";
            const message =
                "The document you are redacting has " +
                totalPages +
                " but you've only reviewed " +
                visitedCount +
                ". Are you sure you want to continue?";
            obs = this.dialog
                .open(ConfirmDialog, {
                    data: { message: message },
                    disableClose: true,
                    hasBackdrop: true,
                    minWidth: "50vw",
                })
                .afterClosed();
        } else {
            obs = of(true);
        }
        obs.subscribe((c: boolean) => {
            if (c) {
                this.dialog
                    .open(ConfirmDialog, {
                        data: {
                            message:
                                "Are you sure you want to accept the redactions as shown?  The operation cannot be undone.",
                        },
                        disableClose: true,
                        hasBackdrop: true,
                        minWidth: "50vw",
                    })
                    .afterClosed()
                    .subscribe((confirm: boolean) => {
                        if (confirm) {
                            const v = {
                                attributes: this.fullObject?.attributes,
                                redactions: this.allRedactions.filter(
                                    (r: any) => !this.redactionIsVisible(r),
                                ),
                                initial_redaction_completed: true,
                            };
                            if ("detected_phi" in v.attributes)
                                delete v.attributes?.detected_phi;
                            this.onAutosave(v);
                        } else {
                            this.handleCancel();
                        }
                    });
            }
        });
    }
    transformPoint(
        point: Point,
        transform: number[] = [1, 0, 0, 1, 0, 0],
        resolution: number[] = [72, 72],
    ): Point {
        const xp = point.x * transform[0] + point.y * transform[2] + transform[4];
        const yp = point.x * transform[1] + point.y * transform[3] + transform[5];
        const xscale = resolution[0] / 72;
        const yscale = resolution[1] / 72;
        return { x: xp * xscale, y: yp * yscale };
    }
    inverseTransform(transform: number[]): number[] {
        const a = transform[0];
        const b = transform[1];
        const c = transform[2];
        const d = transform[3];
        const e = transform[4];
        const f = transform[5];
        const denom = a * d - b * c;
        const ap = d / denom;
        const bp = (b * -1) / denom;
        const cp = (c * -1) / denom;
        const dp = a / denom;
        const ep = (c * f - d * e) / denom;
        const fp = (b * e - a * f) / denom;
        return [ap, bp, cp, dp, ep, fp];
    }
    untransformPoint(
        point: Point,
        transform: number[],
        resolution: number[] = [72, 72],
    ): Point {
        const xscale = resolution[0] / 72;
        const yscale = resolution[1] / 72;
        const pt = { x: point.x / xscale, y: point.y / yscale };
        return this.transformPoint(pt, this.inverseTransform(transform));
    }
    combineMatrices(a: number[], b: number[]): number[] {
        return [
            a[0] * b[0] + a[1] * b[2],
            a[0] * b[1] + a[1] * b[3],
            a[2] * b[0] + a[3] * b[2],
            a[2] * b[1] + a[3] * b[3],
            a[4] * b[0] + a[5] * b[2] + b[4],
            a[4] * b[1] + a[5] * b[3] + b[5],
        ];
    }
    redactionBoundingBox(redaction: any): BoundingBox {
        const resolution = redaction.resolution || (this.isPDF ? [96, 96] : [72, 72]);

        if (redaction["box_method"] == "trs") {
            const p0 = {
                x: redaction.bounding_box.Left,
                y: redaction.bounding_box.Top,
            };
            const p1 = {
                x: p0.x + redaction.bounding_box.Width,
                y: p0.y + redaction.bounding_box.Height,
            };
            const transform = this.combineMatrices(
                redaction.transform ?? [1, 0, 0, 1, 0, 0],
                redaction.rotation ?? redaction.rotate ?? [1, 0, 0, 1, 0, 0],
            );
            const f0 = this.transformPoint(p0, transform, resolution);
            const f1 = this.transformPoint(p1, transform, resolution);
            return {
                origin: { x: Math.min(f0.x, f1.x), y: Math.min(f0.y, f1.y) },
                size: { width: Math.abs(f1.x - f0.x), height: Math.abs(f1.y - f0.y) },
            };
        } else {
            const p0 = {
                x: redaction.bounding_box.Left,
                y: redaction.bounding_box.Top,
            };
            const p1 = {
                x: p0.x + redaction.bounding_box.Width,
                y: p0.y + redaction.bounding_box.Height,
            };
            const transform = redaction.transform || [
                this.imageWidth,
                0,
                0,
                this.imageHeight,
                0,
                0,
            ];
            const t0 = this.transformPoint(p0, transform, resolution);
            const t1 = this.transformPoint(p1, transform, resolution);
            return {
                origin: { x: Math.min(t0.x, t1.x), y: Math.min(t0.y, t1.y) },
                size: { width: Math.abs(t1.x - t0.x), height: Math.abs(t1.y - t0.y) },
            };
        }
    }
    boundingBoxStyle(bbox: BoundingBox): any {
        return {
            "left.px": bbox.origin.x,
            "top.px": bbox.origin.y,
            "width.px": bbox.size.width + 1,
            "height.px": bbox.size.height + 1,
        };
    }

    onRightClick(event: MouseEvent, redaction?: any): void {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX;
        this.contextMenuPosition.y = event.clientY;
        if (this.contextMenuTrigger) {
            this.contextMenuTrigger.menuData = { item: redaction };
            this.contextMenuTrigger.openMenu();
        }
    }
    removeRedaction(redaction: any): void {
        if (this.fullObject)
            this.fullObject.attributes.detected_phi =
                this.fullObject.attributes.detected_phi!.filter(
                    (r: any) => r !== redaction,
                ); // remove the redaction
    }
    removeAllRedactions(): void {
        if (this.fullObject) this.fullObject.attributes.detected_phi = [];
    }
    resetRedactions(): void {
        if (this.originalAttributes) {
            const attributes = JSON.stringify(this.originalAttributes);
            this.fullObject!.attributes = JSON.parse(attributes);
        } else {
            this.fullObject!.attributes = {};
        }
    }

    get redactionsChanged(): boolean {
        return (
            !!this.allRedactions.length ||
            !!this.originalAttributes?.detected_phi?.length
        );
    }

    get hasChanges(): boolean {
        return this.mode == ObjectViewMode.Edit && this.redactionsChanged;
    }
    redacted: Document[] = [];

    protected onCommitSuccess(v: Document): boolean {
        this.phiDocuments = this.phiDocuments.filter((d) => {
            const needsRedaction = d.id !== v.id;
            if (!needsRedaction) this.redacted.push(d);
            return needsRedaction;
        });

        if (this.phiDocuments.length) {
            this.mode = ObjectViewMode.Edit;
            this.object = this.phiDocuments[0];
        } else if (this.phiDocumentsTotal) {
            const combined = [
                ...new Map(
                    [...this.redacted, ...this.uploadedDocuments].map((v) => [
                        v.id!,
                        v,
                    ]),
                ).values(),
            ];
            if (this.bulkMode) {
                this.handleBulkUpload(combined);
            } else {
                this.onAfterDone();
            }
        } else {
            this.object = v;
        }
        return false;
    }

    checkForPHI(documents: Document[], copied: boolean = false): void {
        // Check if we need to display the redaction dialog
        this.phiDocuments = documents.filter(
            (d: Document) => !!d.attributes?.detected_phi?.length,
        );
        this.phiDocumentsTotal = this.phiDocuments.length;

        if (this.phiDocuments.length) {
            let message = undefined;
            if (this.phiDocuments.length == 1) {
                if (documents.length == 1)
                    message =
                        "The document you uploaded may contain Protected Health Information. You will be asked to review and redact the document in the next step.";
                else
                    message =
                        "One of the documents you uploaded may contain Protected Health Information. You will be asked to review and redact the document in the next step.";
            } else if (this.phiDocuments.length == documents.length)
                message =
                    "The documents you uploaded may contain Protected Health Information. You will be asked to review and redact the documents in the next steps.";
            else
                message =
                    this.phiDocuments.length.toString() +
                    " of the documents you uploaded may contain Protected Health Information. You will be asked to review and redact the documents in the next steps.";
            this.dialog
                .open(ConfirmDialog, {
                    data: {
                        title: "Protected Health Information Detected",
                        message: message,
                    },
                    disableClose: true,
                    hasBackdrop: true,
                    minWidth: "50vw",
                })
                .afterClosed()
                .subscribe((confirm: boolean) => {
                    if (confirm) {
                        this.mode = ObjectViewMode.Edit;
                        this.object = this.phiDocuments[0];
                    } else this.handleCancel();
                });
        } else if (this.bulkMode) {
            this.handleBulkUpload(this.uploadedDocuments);
        } else {
            this.onAfterDone(copied);
        }
    }

    onAfterDone(copied: boolean = false): void {
        const message =
            "File(s) " + (copied ? "copied" : "uploaded") + " successfully.";
        this.snackbar.open(message, undefined, { duration: 3000 });
        if (this.assignment) {
            this.dialog
                .open(CompleteTodoComponent, {
                    data: {
                        assignment: this.assignment,
                    },
                    minWidth: "50%",
                    disableClose: true,
                    hasBackdrop: true,
                })
                .afterClosed()
                .subscribe((assignment) => {
                    if (!this.bulkMode) {
                        this.dialogReference?.close();
                    }

                    if (assignment) {
                        this.tabChangeService.changeTab(CASE_TAB_NAMES.CHECK_LIST, {
                            references: { assignment },
                        });
                    }
                });
        } else {
            this.dialogReference?.close();
        }
    }

    get addedRedactionBox(): BoundingBox | undefined {
        if (this.dragging) {
            const offset = this.isPDF ? 0 : this.pxOffset;

            const originX = this.dragging.origin.x - offset;
            const originY = this.dragging.origin.y - offset;
            const currentX = this.dragging.current.x - offset;
            const currentY = this.dragging.current.y - offset;

            const minX = Math.min(originX, currentX);
            const minY = Math.min(originY, currentY);
            const maxX = Math.max(originX, currentX);
            const maxY = Math.max(originY, currentY);

            return {
                origin: { x: minX, y: minY },
                size: { width: maxX - minX, height: maxY - minY },
            };
        }
        return undefined;
    }

    dragging?: { origin: { x: number; y: number }; current: { x: number; y: number } } =
        undefined;
    onMouseDown(event: MouseEvent): void {
        if (this.mode == ObjectViewMode.Edit) {
            this.dragging = {
                origin: { x: event.offsetX - 1, y: event.offsetY - 1 },
                current: { x: event.offsetX - 1, y: event.offsetY - 1 },
            };
        }
    }

    private calculateBoundingBox(p0: Point, p1: Point): any {
        return {
            Left: Math.min(p0.x, p1.x),
            Top: Math.min(p0.y, p1.y),
            Width: Math.abs(p1.x - p0.x),
            Height: Math.abs(p1.y - p0.y),
        };
    }

    private calculateTransform(resolution: number[], page: any): number[] {
        const xscale = resolution[0] / 72;
        const yscale = resolution[1] / 72;
        let transform = [
            this.imageWidth / xscale,
            0,
            0,
            this.imageHeight / yscale,
            0,
            0,
        ];
        if (page) {
            let pageTransform = page.transform ?? [1, 0, 0, 1, 0, 0];
            let pageRotate = page.rotation ?? [1, 0, 0, 1, 0, 0];
            transform = this.combineMatrices(pageTransform, pageRotate);
        }
        return transform;
    }

    onMouseUp(event: MouseEvent): void {
        if (this.dragging) {
            const resolution = this.isPDF ? [96, 96] : [72, 72];
            const page = this.fullObject?.attributes?.pages?.[this.page - 1];
            const transform = this.calculateTransform(resolution, page);

            const offset = this.isPDF ? 0 : this.pxOffset;

            const p0 = this.untransformPoint(
                {
                    x: this.dragging.origin.x - offset,
                    y: this.dragging.origin.y - offset,
                },
                transform,
                resolution,
            );
            const p1 = this.untransformPoint(
                {
                    x: this.dragging.current.x - offset,
                    y: this.dragging.current.y - offset,
                },
                transform,
                resolution,
            );

            const boundingBox = this.calculateBoundingBox(p0, p1);

            const redaction: any = {
                score: 1.0,
                added: true,
                page: this.page - 1,
                bounding_box: boundingBox,
                transform: transform,
                resolution: resolution,
            };

            if (page) {
                redaction["box_method"] = "trs";
                redaction["rotate"] = page.rotation ?? [1, 0, 0, 1, 0, 0];
                redaction["transform"] = page.transform ?? [1, 0, 0, 1, 0, 0];
            }

            if (this.fullObject && !this.fullObject.attributes)
                this.fullObject.attributes = {};
            if (this.fullObject && !this.fullObject.attributes.detected_phi)
                this.fullObject.attributes.detected_phi = [];
            this.fullObject?.attributes.detected_phi.push(redaction);
            this.dragging = undefined;
        }
    }

    onMouseEnter(event: MouseEvent): void {
        if (this.dragging && !event.buttons) {
            // They left and came back, but without the button held, cancel the create
            this.dragging = undefined;
        }
    }
    onMouseLeave(event: MouseEvent): void {}
    onMouseMove(event: MouseEvent): void {
        if (this.dragging) {
            this.dragging.current = { x: event.offsetX - 1, y: event.offsetY - 1 };
        }
    }

    // for now, these don't do anything but to satify the linter
    onKeyDown(event: KeyboardEvent): void {}
    onKeyPress(event: KeyboardEvent): void {}
    onKeyUp(event: KeyboardEvent): void {}
}
