<div class="wrapper absolute flex stack">
    <div class="inquiry-overview flex mat-elevation-z4 pharma">
        <div class="header-panel flex stack case-reference">
            Inquiry {{ fullObject?.reference_identifier }}
        </div>
        <div class="header-panel flex stack physician">
            {{ physicianName }}
        </div>
        <div class="header-panel flex stack logo-panel">
            {{ patientInstitution }}
        </div>
        <div class="header-panel flex stack">
            <div>Inquiry Status</div>
            <mat-chip
                class="case-status-chip"
                *ngIf="
                    !caseStatus?.attributes?.is_default_inquiry_status &&
                    !caseStatus?.attributes?.closes_case
                "
                color="accent"
                selected
                mat-icon-button
                [matMenuTriggerFor]="menu"
            >
                {{ caseStatus?.display_name }}
            </mat-chip>
            <mat-chip
                class="case-status-chip"
                *ngIf="caseStatus?.attributes?.closes_case"
                color="warn"
                mat-icon-button
                selected
                [matMenuTriggerFor]="menu"
            >
                {{ caseStatus?.display_name }}
            </mat-chip>
            <mat-chip
                class="case-status-chip"
                *ngIf="caseStatus?.attributes?.is_default_inquiry_status"
                selected
                mat-icon-button
                [matMenuTriggerFor]="menu"
            >
                {{ caseStatus?.display_name }}
            </mat-chip>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    *ngFor="let status of caseStatuses"
                    (click)="changeStatus($event, status)"
                    [disabled]="!canChangeStatus(status)"
                >
                    <span>{{ status.display_name }}</span>
                </button>
            </mat-menu>
        </div>
    </div>
    <ng-container *ngIf="mode != ObjectViewMode.Create">
        <div *ngIf="!fullObject || !currentAccount" class="spinner">
            <mat-spinner mode="indeterminate" [diameter]="50"></mat-spinner>
            <div class="spinner-message">Loading Inquiry ...</div>
        </div>
        <div
            *ngIf="fullObject && currentAccount"
            class="wrapper flexible flex stack inquiry-view"
            cdkScrollable
        >
            <mat-tab-group>
                <mat-tab label="Details">
                    <ng-template matTabContent>
                        <div class="content-card flex stack" *ngIf="currentAccount">
                            <div class="title">Inquiry Details</div>
                            <div class="detailContainer">
                                <mat-form-field color="primary" class="inquiryDetail">
                                    <mat-label>Country</mat-label>
                                    <mat-select [formControl]="countryControl">
                                        <mat-select-trigger
                                            *ngIf="countryControl?.value"
                                        >
                                            <mat-icon
                                                [matTooltip]="countryInvalidToolTip"
                                                class="warning country-flag-img"
                                                *ngIf="
                                                    isObject(fullObject) &&
                                                    !fullObject.country_valid
                                                "
                                            >
                                                warning
                                            </mat-icon>
                                            <img
                                                *ngIf="countryControl?.value"
                                                [src]="countryControl.value.flag_url"
                                                alt="({{ countryControl.value.code }})"
                                                class="country-flag-img"
                                                (error)="
                                                    handleCountryFlagError(
                                                        countryControl.value
                                                    )
                                                "
                                            />
                                            {{ countryControl.value?.display_name }}
                                        </mat-select-trigger>
                                        <ng-container *ngIf="countries.length">
                                            <mat-option
                                                *ngFor="let country of countries"
                                                [value]="country"
                                            >
                                                <img
                                                    [src]="country.flag_url"
                                                    alt="({{ country.code }})"
                                                    class="country-flag-img"
                                                    (error)="
                                                        handleCountryFlagError(country)
                                                    "
                                                />
                                                {{ country.display_name }}
                                            </mat-option>
                                        </ng-container>
                                        <ng-container *ngIf="!countries.length">
                                            <mat-option disabled>
                                                Error - Countries list not found
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field color="primary" class="inquiryDetail">
                                    <mat-label>Product</mat-label>
                                    <mat-select [formControl]="productControl">
                                        <mat-select-trigger
                                            *ngIf="productControl?.value"
                                        >
                                            <mat-icon
                                                [matTooltip]="productInvalidToolTip"
                                                class="warning country-flag-img"
                                                *ngIf="
                                                    isObject(fullObject) &&
                                                    !fullObject.product_valid
                                                "
                                            >
                                                warning
                                            </mat-icon>
                                            <span>
                                                {{ productControl.value.displayName }}
                                            </span>
                                        </mat-select-trigger>
                                        <mat-option
                                            [value]="fullObject.product"
                                            *ngIf="fullObject?.product?.displayName"
                                        >
                                            {{ fullObject.product?.displayName }}
                                        </mat-option>
                                        <mat-option
                                            *ngFor="let product of products"
                                            [value]="product"
                                        >
                                            {{ product.displayName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field
                                    color="primary"
                                    class="inquiryDetail"
                                    *ngIf="isPharmaStaff"
                                >
                                    <mat-label>Assigned To</mat-label>
                                    <mat-select
                                        [formControl]="assignee"
                                        *ngIf="fullObject"
                                    >
                                        <mat-option
                                            (click)="unAssign()"
                                            *ngIf="isAssigned"
                                        >
                                            Unassign
                                        </mat-option>
                                        <mat-option
                                            *ngFor="
                                                let teamMember of availableAssignees
                                            "
                                            [value]="teamMember.account"
                                        >
                                            {{ teamMember.displayName }}
                                            <span class="small option-spacer">
                                                {{ teamMember.email }}
                                            </span>
                                        </mat-option>
                                        <mat-option
                                            disabled
                                            *ngIf="!availableAssignees.length"
                                        >
                                            Error - Assignees list not found
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint *ngIf="!viewOnly">
                                        <span
                                            class="pointer brand-medium"
                                            (click)="assignToMe($event)"
                                            *ngIf="!isAssignedToMe"
                                        >
                                            <mat-icon>assignment_ind</mat-icon>
                                            Assign to Me
                                        </span>
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="content-card">
                            <div class="card-header flex">
                                <div class="spacer"></div>
                                <div class="header-actions flex">
                                    <button
                                        [class.button-disabled]="
                                            !summary?.dataForms?.length
                                        "
                                        mat-flat-button
                                        class="brand-medium"
                                        [disabled]="exporting"
                                        (click)="exportAsPdf($event)"
                                    >
                                        <mat-icon matTooltip="Download">
                                            file_download
                                        </mat-icon>
                                        Export Details
                                    </button>
                                    <button
                                        [class.button-disabled]="viewOnly"
                                        mat-flat-button
                                        class="brand-medium"
                                        [matTooltip]="closeTooltip"
                                        (click)="closeCase($event)"
                                        *ngIf="
                                            isPharmaStaff &&
                                            isInquiryActive &&
                                            canPerformInquiryActions
                                        "
                                    >
                                        <mat-icon>close</mat-icon>
                                        Close Inquiry
                                    </button>
                                    <button
                                        [class.button-disabled]="viewOnly"
                                        mat-flat-button
                                        class="brand-medium"
                                        [matTooltip]="responseTooltip"
                                        (click)="quickResponse($event)"
                                    >
                                        <mat-icon>reply</mat-icon>
                                        Quick Response
                                    </button>
                                    <button
                                        [class.button-disabled]="!canCreateCase"
                                        mat-flat-button
                                        class="brand-medium"
                                        [matTooltip]="caseTooltip"
                                        (click)="createCase($event)"
                                        *ngIf="
                                            isPharmaStaff &&
                                            isInquiryActive &&
                                            canPerformInquiryActions
                                        "
                                    >
                                        <mat-icon>open_in_new</mat-icon>
                                        Create Case
                                    </button>
                                </div>
                            </div>
                            <case-summary
                                [showExportButton]="false"
                                [repository]="fullObject"
                            ></case-summary>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Documents">
                    <ng-template mat-tab-label>
                        <div class="flex">
                            <div
                                class="flex"
                                style="padding-right: 1rem"
                                [matBadge]="matBadge(documentNotifications || [])"
                                matBadgeColor="accent"
                                matBadgeOverlap="false"
                                matBadgePosition="after"
                            >
                                <span class="flexible">Documents</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template matTabContent>
                        <div class="content-card">
                            <document-repository
                                [viewOnly]="viewOnly"
                                *ngIf="!!fullObject"
                                [sources]="documentSources"
                                [repository]="fullObject"
                                [owner]="fullObject.organization"
                                [notifications]="documentNotifications"
                                [documentToOpen]="documentToOpen"
                                (documentToOpenUsed)="documentToOpen = undefined"
                            ></document-repository>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Communications">
                    <ng-template mat-tab-label>
                        <div
                            class="flex"
                            [matBadge]="matBadge(messageNotifications || [])"
                            matBadgeColor="accent"
                            matBadgeOverlap="false"
                        >
                            <span class="flexible">Communications</span>
                        </div>
                    </ng-template>
                    <ng-template matTabContent>
                        <div class="content-card">
                            <communications
                                *ngIf="!!fullObject"
                                [repository]="fullObject"
                                [viewOnly]="viewOnly"
                                [notifications]="messageNotifications"
                                [messageToOpen]="messageEvent"
                                (messageToOpenUsed)="messageEvent = undefined"
                            ></communications>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </ng-container>
</div>
