import { debounceTime, filter } from "rxjs/operators";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from "@angular/core";
import { ObjectAdminComponent } from "../object-admin.component";
import { Template } from "../../../services/models/template";
import { TemplateService } from "../../../services/notification.services";
import { TemplateComponent } from "./template.component";
import { ObjectViewEntryPoint, ObjectViewMode } from "../object.component";
import { UntypedFormControl } from "@angular/forms";
import { Sort } from "@angular/material/sort";
import { ObjectRepository } from "src/services/models/compound";
import { RequestFilter } from "src/common/utilities/request";
import { MatDialogConfig } from "@angular/material/dialog";

@Component({
    selector: "template-list",
    templateUrl: "./template-list.component.html",
    styleUrls: ["./template.component.scss"],
})
export class TemplateListComponent extends ObjectAdminComponent<Template> {
    @Input() headerTitle: string = "Templates";
    @Input() viewOnly = false;
    objectView = TemplateComponent;
    tabSubtitle(object: Template): string {
        return object.owner ?
                object.owner.displayName + " Email Template"
            :   "Email Template";
    }

    displayedColumns: string[] = [];
    defaultOrdering: any[] = [];
    showSearch: boolean = false;
    searchTermControl: UntypedFormControl = new UntypedFormControl();
    @ViewChild("search") searchElement?: ElementRef;

    owner_?: ObjectRepository;
    @Input() set owner(v: ObjectRepository | undefined) {
        this.owner_ = v;
        this.list.refresh();
    }
    get owner(): ObjectRepository | undefined {
        return this.owner_;
    }
    @Input() infiniteScroll: boolean = false;
    @Input() inCard: boolean = false;
    @Input() showTypes: boolean = false;
    @Input() others: any[] = [];

    constructor(
        protected service: TemplateService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10);
    }

    ngOnInit(): void {
        this.getDisplayedColumns();
        if (!!this.others.length && this.showTypes) {
            this.defaultOrdering = [
                { field: "owner_type", ascending: false },
                { field: "name", ascending: true },
            ];
        }
        this.list.ordering = this.defaultOrdering;
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.searchTermControl.valueChanges
            .pipe(
                debounceTime(400),
                filter(() => !!this.list),
                filter(
                    (term: string) =>
                        !term || term.length >= this.list.minimumFilterLength,
                ),
            )
            .subscribe((term: string) => this.updateList(term));
    }

    handleEditClick(event: MouseEvent, template: any): void {
        const viewOnly = !this.isOwnerType(template) || this.viewOnly;
        this.editObject(event, template, true, viewOnly);
    }

    isOwnerType(template: Template): boolean {
        return template.owner?.type == this.owner?.type;
    }

    resetSearchTerm(event?: MouseEvent): void {
        this.terminateEvent(event);
        this.searchTermControl.setValue(undefined);
        this.showSearch = false;
        this.updateList(null);
    }
    toggleSearch(event: MouseEvent): void {
        this.terminateEvent(event);
        this.showSearch = !this.showSearch;
        if (this.showSearch)
            setTimeout(() => this.searchElement?.nativeElement.focus());
    }
    autoToggleSearch(): void {
        if (!this.searchTermControl.value) {
            this.searchTermControl.setValue(undefined);
            this.showSearch = false;
        }
    }
    onFocusOut(event: any): void {
        this.autoToggleSearch();
    }
    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = this.defaultOrdering;
    }

    get isSearchEmpty(): boolean {
        return !this.showSearch && this.searchTermControl.value == undefined;
    }

    newObject(data?: any): Template | undefined {
        return super.newObject({
            ...(data || {}),
            owner: this.owner?.asReference,
        });
    }

    editObject(
        event: MouseEvent,
        object: Template,
        asDialog?: boolean,
        viewOnly?: boolean,
    ): ObjectViewEntryPoint<Template> | undefined {
        const instance = super.editObject(event, object, asDialog, viewOnly);
        if (instance) instance.autosave = false;
        return instance;
    }

    isProgramTemplate(template: Template): boolean {
        return !!(template.owner?.type == "program.program");
    }

    isOrgTemplate(template: Template): boolean {
        return !!(template.owner?.type == "iam.organization");
    }

    isCountryTemplate(template: Template): boolean {
        return !!(template.owner?.type == "program.programcountry");
    }

    getBadgeColor(template: Template): string {
        return template.owner?.type == this.owner?.type ? "primary" : "accent";
    }

    getDisplayedColumns(): void {
        this.displayedColumns =
            this.showTypes ? ["name", "owner_type", "actions"] : ["name", "actions"];
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["owner"] = this.owner ? this.owner.id! : "0";
        this.others.forEach((obj) => {
            filters["owner"] += ",";
            filters["owner"] += obj.id;
        });
        return filters;
    }

    protected objectDialogConfiguration(
        object: Template,
        mode: ObjectViewMode,
    ): MatDialogConfig {
        return {
            ...(super.objectDialogConfiguration(object, mode) || {}),
            width: "80%",
        };
    }

    // for now, these don't do anything but to satify the linter
    onKeyDown(event: KeyboardEvent): void {}
    onKeyPress(event: KeyboardEvent): void {}
    onKeyUp(event: KeyboardEvent): void {}
}
