<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
>
    <div class="flex stack">
        <mat-form-field class="flexible">
            <mat-progress-spinner
                *ngIf="validatingName"
                matSuffix
                diameter="20"
                class="inline"
                mode="indeterminate"
            ></mat-progress-spinner>
            <mat-label>Program Name</mat-label>
            <input
                [errorStateMatcher]="errorMatcher"
                matInput
                formControlName="name"
                placeholder="Program Name"
            />
            <mat-error *ngIf="programNameExists">
                A program with this name already exists.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="flexible">
            <mat-label>Program Type</mat-label>
            <mat-select formControlName="program_type">
                <mat-option *ngFor="let type of availableProgramTypes" [value]="type">
                    {{ type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="flexible">
            <mat-label>Products</mat-label>
            <mat-select formControlName="products" multiple>
                <mat-option *ngFor="let product of availableProducts" [value]="product">
                    {{ product.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="flexible">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status">
                <mat-option *ngFor="let status of availableStatuses" [value]="status">
                    {{ status }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="flexible">
            <mat-label>Program Intake Form</mat-label>
            <mat-select formControlName="intake_form">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let form of availableForms" [value]="form">
                    {{ form.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="flexible">
            <mat-label>Program's Estimated Open Date</mat-label>
            <input
                matInput
                [matDatepicker]="picker"
                placeholder="Choose a date"
                [min]="today"
                formControlName="open_date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
</object-form>
