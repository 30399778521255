<div class="container">
    <div class="templates-header flex">
        <div class="title">{{ headerTitle }}</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field
                color="primary"
                *ngIf="!isSearchEmpty"
                class="template-search margin-right"
            >
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Templates</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSearchEmpty"
                (click)="toggleSearch($event)"
                matTooltip="Search Templates"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                [disabled]="viewOnly"
                mat-flat-button
                class="brand-medium"
                (click)="createObject($event, true)"
            >
                <mat-icon>open_in_new</mat-icon>
                Create Template
            </button>
        </div>
    </div>
    <mat-table [dataSource]="list" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell
                *matCellDef="let template"
                (click)="handleEditClick($event, template)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                {{ template.displayName }}
            </mat-cell>
        </ng-container>
        <ng-container *ngIf="showTypes" matColumnDef="owner_type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
            <mat-cell
                *matCellDef="let template"
                (click)="handleEditClick($event, template)"
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                <mat-chip-set>
                    <mat-chip
                        color="{{ getBadgeColor(template) }}"
                        selected
                        *ngIf="isProgramTemplate(template)"
                    >
                        Program
                    </mat-chip>

                    <mat-chip
                        color="{{ getBadgeColor(template) }}"
                        selected
                        *ngIf="isOrgTemplate(template)"
                    >
                        Organization
                    </mat-chip>

                    <mat-chip
                        color="{{ getBadgeColor(template) }}"
                        selected
                        *ngIf="isCountryTemplate(template)"
                    >
                        Country
                    </mat-chip>

                    <mat-chip
                        color="{{ getBadgeColor(template) }}"
                        selected
                        *ngIf="
                            !isCountryTemplate(template) &&
                            !isProgramTemplate(template) &&
                            !isOrgTemplate(template)
                        "
                    >
                        Country
                    </mat-chip>
                </mat-chip-set>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let template" class="overflow">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ template: template }"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="no-records">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                No templates found.
            </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row
            *matFooterRowDef="['no-records']"
            [class.hidden-footer]="!!list.list.items.length"
        ></mat-footer-row>
        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-template="template">
                @if (viewOnly) {
                    <button
                        mat-menu-item
                        (click)="editObject($event, template, true, viewOnly)"
                    >
                        View
                    </button>
                } @else {
                    <button
                        mat-menu-item
                        (click)="editObject($event, template, true)"
                        [disabled]="!isOwnerType(template)"
                    >
                        Edit
                    </button>
                    <button
                        mat-menu-item
                        (click)="deleteObject($event, template)"
                        [disabled]="!isOwnerType(template)"
                    >
                        Delete
                    </button>
                }
            </ng-template>
        </mat-menu>
    </mat-table>
    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
