import { TeamMember } from "src/services/models/team";
import { Injectable, inject } from "@angular/core";
import { APIService } from "./api.service";
import { Program, ProgramCountry } from "./models/program";
import { Observable, Subject, forkJoin, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Inquiry } from "./models/inquiry";
import { Case, CaseAudit, Status } from "./models/case";
import { DiscussionEntry } from "./models/discussion";
import { Role } from "./models/role";
import { Account } from "./models/account";
import { Document, DocumentOwner, DocumentRepository } from "./models/document";
import { FileItem } from "ng2-file-upload";
import { HttpErrorResponse } from "@angular/common/http";
import { Assignment, AssignmentGroup, AssignmentReference } from "./models/assignment";
import {
    APIObject,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
    ProgramReference,
} from "./models/api-object";

import { Intake, IntakeSettings } from "./models/intake";
import { Workflow, WorkflowInstance, WorkflowReference } from "./models/workflow";
import { RejectCaseDialog } from "src/program/components/case/reject-case.component";
import { SendTemplateDialog } from "src/common/components/template/send-template.dialog";
import { Patient } from "./models/patient";
import { CaseTeam, Team } from "./models/team";
import { FileUploaderCustom } from "src/common/utilities/FileUploaderCustom";
import { RequestFilter, queryStringFromFilters } from "src/common/utilities/request";
import { Message } from "./session.service";
import { defined } from "src/common/utilities/flatten";
import { Task } from "./models/task";
import { MatDialog } from "@angular/material/dialog";
import { Product } from "./models/product";
import { TabError } from "src/common/components/object.component";
import { DocusignAccount } from "./models/docusign";
import { Organization } from "./models/organization";
import { Country, CountryLatLong } from "./models/country";
import { DataForm } from "./models/data";
import { isValidUUID } from "src/common/utilities/utilities";

/*
Program Service
    - list, create, retrieve, update, delete operations supported normally
    - available list filters:
        admin: a single Account id - will return all programs in an organization for which the specified account has the 'program.administrator' role
 */
@Injectable()
export class ProgramService extends APIService<Program> {
    constructor() {
        super(Program, ["program", "program"]);
    }

    validateProgramName(name: string, organization: ObjectOrReference<Organization>) {
        const data = {
            name,
            organization: organization.asReference.serialize(),
        };

        return this.request<boolean>(
            [this.endpoint, "program_name_exists"].join("/"),
            undefined,
            data,
            "post",
        ).pipe(map((result) => result as boolean));
    }

    getReferenceType(): typeof ObjectReference {
        return ProgramReference;
    }
    /* Helper function to add or remove a CORS domain to a program
        program: a program Object - the program object to add a CORS domain to
        domain: string - the domain to add/remove
        remove: boolean - whether to remove the domain or add it
        return observable: list of string - the full list of CORS domains associated with this object
    * */
    setCORSAccessDomain(
        program: Program,
        domain?: string,
        remove: boolean = false,
    ): Observable<string[]> {
        return this.request(
            [this.endpoint, program.id, "cors"].join("/"),
            undefined,
            { domain: domain, remove: remove ? "True" : "False" },
            "post",
        ).pipe(map((res: any) => res as string[]));
    }

    /* Helper function to add a role to an account for a specific program
     *   program: Case Object - the case that the specified account should have roles to
     *   account: Account Object - the account that is receiving the role
     *   role: string - the specific role to give the account for the specified program
     *   return observable: a list of Role Objects - the full list of roles for the specified program
     * */
    addRole(program: Program, role: string, account: Account): Observable<Role[]> {
        const data = {
            account: account,
            role: role,
        };
        return this.request<Role[]>(
            [this.endpoint, program.id, "role"].join("/"),
            undefined,
            data,
            "post",
        ).pipe(
            map((result: any) =>
                result.map((o: any) => ObjectFactory.makeObject<Role>(o)),
            ),
        );
    }
    deleteRole(
        program: Program,
        role: string,
        account: Account | ObjectReference,
    ): Observable<Role[]> {
        const data = {
            account: account,
            role: role,
        };
        return this.request<Role[]>(
            [this.endpoint, program.id, "role"].join("/"),
            undefined,
            data,
            "delete",
        ).pipe(
            map((result: any) =>
                result.map((o: any) => ObjectFactory.makeObject<Role>(o)),
            ),
        );
    }
    checkIND(ind: string): Observable<string | undefined> {
        const data: RequestFilter = {
            ind: ind,
        };
        const endpoint =
            [this.endpoint, "check"].join("/") + queryStringFromFilters(data);
        return this.request<any>(endpoint).pipe(map((result: any) => result.ind));
    }
    getProductsByCountry(countryCode: string): Observable<Program[]> {
        const endpoint =
            [this.endpoint, "products"].join("/") + `?country=${countryCode}`;
        return this.request(endpoint).pipe(
            map((result: any) =>
                result.map((productData: any) => new Program(productData)),
            ),
        );
    }
}

/*
Inquiry Service
    - list, create, retrieve, update, delete operations supported normally
    - available list filters:
        admin: a single Account id - will return all inquiries owned by an organization for which the specified account has the 'program.administrator' role
        status: a list of status values - will return all inquiries that match the specfied statues
            statuses are evaluated in the order that they are presented in the list,  to exclude a specific status, prepend '!' to the value
            example: '!spam,!case'  will return all inquiries that are not marked as spam and not converted to a case
            valid status values defined in inquiry model
 */
@Injectable()
export class InquiryService extends APIService<Inquiry> {
    protected dialog: MatDialog;
    constructor() {
        super(Inquiry, ["program", "inquiry"]);
        this.dialog = inject(MatDialog);
    }

    /* Helper function to add a new discussion entry to the shared discussion of a case
        inquiry: Inquiry Object - the inquiry to add discussion to
        entry: DiscussionEntry Object - the new entry to add to the specified case's discussion
        return observable: DiscussionEntry Object - the newly created DiscussionEntry object

        Inquiry objects once converted to a case represent the shared data between parties in a case.  Therefore any discussion added to an inquiry is shared between parties.
        This is not a currently planned feature for the application, but the technical capability exists to allow it.
        If it does become a feature, discussion should not be added until after an inquiry has been converted to a full case
    */
    addDiscussionEntry(
        inquiry: Inquiry,
        entry: DiscussionEntry,
    ): Observable<DiscussionEntry | undefined> {
        const data = {
            ...entry.serialize(),
            object: inquiry.asReference.serialize(),
        };
        return this.request<DiscussionEntry>(
            [this.endpoint, inquiry.id, "discuss"].join("/"),
            undefined,
            data,
            "post",
        ).pipe(
            map((o: any) =>
                ObjectFactory.makeObject<DiscussionEntry>(
                    o,
                    DiscussionEntry.object_type,
                ),
            ),
            mergeMap((o: ObjectOrReference<DiscussionEntry>) =>
                ObjectFactory.objectObservable(o),
            ),
        );
    }
    isProgramCountryValid(
        country: ObjectOrReference<Country>,
        program: ObjectOrReference<Program>,
    ) {
        country = country.asReference.serialize();
        program = program.asReference.serialize();

        const data = {
            country,
            program,
        };

        return this.request<boolean>(
            [this.endpoint, "is_program_country_valid"].join("/"),
            undefined,
            data,
            "get",
        ).pipe(map((result) => result as boolean));
    }
    isCountryValid(
        inquiry: ObjectOrReference<Inquiry>,
        country: ObjectOrReference<Country>,
    ) {
        const path = [this.endpoint, inquiry.id, "is_country_valid"].join("/");
        const data = { country: country.asReference.serialize() };
        return this.request<boolean>(path, undefined, data, "get").pipe(
            map((result) => result as boolean),
        );
    }
    isProductValid(
        product: ObjectOrReference<Product>,
        country: ObjectOrReference<Country>,
        program?: ObjectOrReference<Program>,
        organization?: ObjectOrReference<Organization>,
    ) {
        const data = {
            product: product.asReference.serialize(),
            country: country.asReference.serialize(),
            program: program?.asReference.serialize() ?? {},
            organization: organization?.asReference.serialize() ?? {},
        };
        const path = [this.endpoint, "is_product_valid"].join("/");
        return this.request<boolean>(path, undefined, data, "get").pipe(
            map((result) => result as boolean),
        );
    }
    previewPatchCaseTeam(
        inquiry: ObjectOrReference<Inquiry>,
        product?: ObjectOrReference<Product>,
        country?: ObjectOrReference<Country>,
        program?: ObjectOrReference<Program>,
    ): Observable<CaseTeam | undefined> {
        const data = {
            id: inquiry.id,
            product: product?.asReference.serialize(),
            country: country?.asReference.serialize(),
            program: program?.asReference.serialize(),
        };
        const path = [this.endpoint, "preview_case_team"].join("/");
        return this.request<CaseTeam>(path, undefined, data, "post").pipe(
            map((o: any) => {
                // Strip ids to avoid overwriting cache
                const { id, ...rest } = o;
                return ObjectFactory.makeObject<CaseTeam>(rest, CaseTeam.object_type);
            }),
            mergeMap((o: ObjectOrReference<CaseTeam>) =>
                ObjectFactory.objectObservable(o),
            ),
        );
    }
    intake(data: any): Observable<any> {
        /* this request is unauthenticated */
        return this.request<any>(
            [this.endpoint, "intake"].join("/"),
            undefined,
            data,
            "post",
            undefined,
            true,
            true,
            false,
        );
    }

    //case status is also stored in the inquiry
    close(
        fullObject: Inquiry,
        isInquiry = true,
        status?: Status,
        allStatuses: Status[] = [],
    ) {
        const obs = new Subject<boolean>();
        const dialog = this.dialog.open(RejectCaseDialog, {
            data: {
                object: ObjectFactory.makeObject<Inquiry>(fullObject),
                onChange: (v: Inquiry) => {
                    if (fullObject) fullObject = v;
                },
                rejectionOptions:
                    isInquiry ? Inquiry.RejectionOptions : Case.RejectionOptions,
                title: isInquiry ? "Close Inquiry" : "Close Case",
                rejectCase: !isInquiry,
                dropdownLabel: "Closure reason",
                status,
            },
            disableClose: true,
            hasBackdrop: true,
            minWidth: "50vw",
        });
        dialog.componentInstance.statusses = allStatuses;

        dialog.afterClosed().subscribe((formData) => {
            if (formData.preview) {
                const subject =
                    isInquiry ? "Inquiry Has Been Rejected" : "Case Has Been Closed";
                let displayMessage;

                if (isInquiry) {
                    displayMessage = Inquiry.RejectionOptions.find(
                        (o) => o.value == formData.rejection_status,
                    )?.displayName;
                } else
                    displayMessage = Case.RejectionOptions.find(
                        (o) => o.value == formData.rejection_status,
                    )?.displayName;

                let message = "";
                if (displayMessage) {
                    message = `Reason: ${displayMessage}`;
                    if (formData.rejection_reason) {
                        //currently this field is optional
                        message += `<br/>Rationale: ${formData.rejection_reason}`;
                    }
                }

                const physician = fullObject?.teamMember("provider", "physician");
                const contacts =
                    fullObject?.teamMembers().filter((tm: TeamMember) => !tm.private) ||
                    [];
                const owners = [
                    fullObject?.id,
                    fullObject.program?.id,
                    fullObject.organization?.id,
                ];
                this.dialog
                    .open(SendTemplateDialog, {
                        data: {
                            to: physician,
                            subject,
                            owner: owners.join(","),
                            context: fullObject?.data,
                            reference: fullObject?.asReference,
                            allowInvite: false,
                            contacts: contacts,
                            repository: fullObject,
                            message,
                        },
                        disableClose: true,
                        hasBackdrop: true,
                        minWidth: "50vw",
                    })
                    .afterClosed()
                    .subscribe((message: Message) => {
                        if (message) {
                            //should only get updated if the user sends the email
                            const obj = fullObject;
                            obj.rejection_reason = formData.rejection_reason;
                            obj.rejection_status = formData.rejection_status;
                            obj.case_status =
                                formData?.status === status?.id ?
                                    status!
                                :   allStatuses?.find(
                                        (s) => s.id === formData?.status,
                                    )!;
                            this.update(obj).subscribe(() => {
                                obs.next(true);
                            });
                        }
                    });
            } else if (formData) {
                // MED-1760 no longer have to provide a reason
                const obj = fullObject;
                obj.case_status =
                    formData?.status === status?.id ?
                        status!
                    :   allStatuses?.find((s) => s.id === formData?.status)!;
                this.update(obj).subscribe(() => {
                    obs.next(true);
                });
            } else obs.next(false); //cancel was clicked
        });

        return obs.asObservable();
    }
}

/*
Intake Service
    - currently no CRUD object methods
    - used to retrieve intake configuration
 */
export class IntakeService extends APIService<Intake> {
    constructor() {
        super(Intake, ["program", "intake"]);
    }

    config(orgSlug: string, programSlug?: string): Observable<Intake | undefined> {
        const data = {
            org_slug: orgSlug,
            program_slug: programSlug,
        };
        return this.request<Intake>(
            [this.endpoint, "config"].join("/"),
            undefined,
            data,
            "post",
            undefined,
            undefined,
            undefined,
            false,
        ).pipe(
            map((result: any) => {
                return this.makeObject(result);
            }),
            mergeMap((o: ObjectOrReference<Intake>) => {
                return ObjectFactory.objectObservable(o);
            }),
            catchError((error) => {
                return of(undefined);
            }),
        );
    }
    form(countryId: string, productId: string): Observable<DataForm[]> {
        const filters = {
            country: countryId,
            product: productId,
        };
        return this.request<DataForm[]>(
            [this.endpoint, "form"].join("/"),
            filters,
            undefined,
            "get",
            undefined,
            undefined,
            undefined,
            false,
        ).pipe(
            map((result: any) => result as any[]),
            map((result: any[]) =>
                result.map((item: any) =>
                    ObjectFactory.makeObject<DataForm>(item, DataForm.object_type),
                ),
            ),
            mergeMap((objects: ObjectOrReference<DataForm>[]) => {
                if (objects.length === 0) {
                    return of([]); // Emit an empty array
                }
                return forkJoin(objects.map((o) => ObjectFactory.objectObservable(o)));
            }),
            map((forms: (DataForm | undefined)[]) =>
                forms.filter((form): form is DataForm => form !== undefined),
            ),
        );
    }

    getSettings(owner: APIObject): Observable<IntakeSettings> {
        return this.request(
            [this.endpoint, "org_settings"].join("/"),
            { owner: owner?.id ?? "0" },
            undefined,
            "get",
        ).pipe(map((settings: any) => new IntakeSettings(settings)));
    }
    updateSettings(settings: IntakeSettings): Observable<IntakeSettings> {
        return this.request(
            [this.endpoint, "org_settings"].join("/"),
            undefined,
            settings.serialize(),
            "post",
        ).pipe(map((settings: any) => new IntakeSettings(settings)));
    }
}

@Injectable()
export class DiscussionEntryFactory extends ObjectFactory<DiscussionEntry> {
    constructor() {
        super(DiscussionEntry);
    }
}

/*
Case Service
    - list, create, retrieve, update, delete operations supported normally
    - available list filters:
        access: a list of Account ids - will return a list of cases that any of the specified accounts are either the owners of or have been given access to via a role
 */
@Injectable()
export class CaseService extends APIService<Case> {
    constructor() {
        super(Case, ["program", "case"]);
        inject(DiscussionEntryFactory);
    }

    /* Helper function to add a new discussion entry to a case
        case_: Case Object - the case to add to
        entry: DiscussionEntry Object - the new entry to add to the specified case's discussion
        return observable: DiscussionEntry Object - the newly created DiscussionEntry object
    */
    addDiscussionEntry(
        case_: Case,
        entry: DiscussionEntry,
    ): Observable<DiscussionEntry | undefined> {
        const data = {
            ...entry.serialize(),
            object: case_.asReference.serialize(),
            reference_id: case_.shared.reference_identifier,
        };

        return this.request<DiscussionEntry>(
            [this.endpoint, case_.id, "discuss"].join("/"),
            undefined,
            data,
            "post",
        ).pipe(
            map((o: any) =>
                ObjectFactory.makeObject<DiscussionEntry>(
                    o,
                    DiscussionEntry.object_type,
                ),
            ),
            mergeMap((o: ObjectOrReference<DiscussionEntry>) =>
                ObjectFactory.objectObservable(o),
            ),
        );
    }

    audit(object: Case | ObjectReference, full = false): Observable<CaseAudit[]> {
        const endpoint = [this.endpoint, object.id, "audit"].join("/");
        return this.request<CaseAudit[]>(endpoint).pipe(
            map((result: any) => defined(result.map((o: any) => new CaseAudit(o)))),
        );
    }

    check_case_name_validity(data: any): Observable<any> {
        return this.request(
            [this.endpoint, "check_case_name_validity"].join("/"),
            undefined,
            { ...data },
            "post",
            undefined,
            true,
            true,
            true,
        );
    }

    export(c: Case, filename: string) {
        const path = [this.endpoint, c.id!, "export"];
        return this.request<ExportResponse>(
            path.join("/"),
            undefined,
            { filename },
            "get",
        );
    }
}
export interface ExportResponse {
    task_id: string;
    message?: string;
}

export interface ExportCompleteResponse {
    path: string;
    file_name: string;
}
@Injectable()
export class StatusService extends APIService<Status> {
    availableStatuses: Status[] = [];
    defaultStatuses: Status[] = [];
    constructor() {
        super(Status, ["program", "status"]);
    }

    getAvailableInquiryStatus(filter: any) {
        return this.list(filter).pipe(
            map((statuses) => statuses as Status[]),
            map((statuses) => {
                const organizationStatuses = statuses.filter((s) => !!s.owner);
                const hasDefault = organizationStatuses.some(
                    (s) => s.attributes.is_default_inquiry_status,
                );
                const hasClosingStatus = organizationStatuses.some(
                    (s) => s.attributes.closes_case,
                );
                const normalStatuses = organizationStatuses.filter(
                    (s) =>
                        !s.attributes.closes_case &&
                        !s.attributes.is_default_inquiry_status,
                );
                let availableStatuses = [...normalStatuses];

                if (hasDefault) {
                    const defaultStatus = organizationStatuses.find(
                        (s) => s.attributes.is_default_inquiry_status,
                    );
                    if (defaultStatus) {
                        availableStatuses.push(defaultStatus);
                    }
                }

                if (hasClosingStatus) {
                    const closingStatuses = organizationStatuses.filter(
                        (s) => s.attributes.closes_case,
                    );
                    availableStatuses = availableStatuses.concat(closingStatuses);
                }

                let systemProvidedStatuses = statuses.filter((s) => !s.owner);
                availableStatuses = [...availableStatuses, ...systemProvidedStatuses];
                const obj = {
                    systemProvidedStatuses: systemProvidedStatuses,
                    organizationStatuses,
                    availableStatuses,
                    allStatuses: statuses,
                };
                this.defaultStatuses = obj.systemProvidedStatuses;
                this.availableStatuses = availableStatuses;
                return obj;
            }),
        );
    }
}

export class DocumentFileItem extends FileItem {
    name_alias?: string;
    document_type?: string;
    _uploader?: FileUploaderCustom;
}

/*
Document Service
    - list, retrieve, and delete operations supported normally
    - update should only be used to change document properties, not the file itself.  if a file change is necessary, delete the old object and upload a new one
    - create functionality should use the ng2-file-uploader with the helper function provided
    - available filters for list operation:
        owner: a list of UUIDs - will return all documents owned by any of the provided objects
        repo: a single UUID - will return all documents in the provided object's repository
 */
@Injectable()
export class DocumentService extends APIService<Document> {
    constructor() {
        super(Document, ["program", "document"]);
    }

    /* Helper function to initialize an ng2-file-uploader object with the given owner, repository, and uploaded_by properties
     *   owner: Account or Organization Object (or reference) - the account or organization that owns the uploaded object
     *   repository: Program or Case or Inquiry Object (or reference) - the repository into which to store the document
     *   uploaded_by: Account Object - the account that is uploading the document
     *   onSuccess: callback - an optional callback when a file is successfully uploaded
     *   onError: callback - an optional callback when a file upload fails
     *   return: the FileUploader object to use in a document management component
     *
     *   On successful upload, the file uploader will also generate an objectCreated event on this service by default before calling the provided success callback
     *   On unsuccessful upload, the file uploader will call the session error handler by default
     * */
    fileUploader(
        owner: DocumentOwner,
        repository: DocumentRepository,
        uploaded_by: Account,
        onSuccess?: (documents: Document[]) => void,
        onError?: (err: HttpErrorResponse) => void,
    ): Observable<FileUploaderCustom> {
        return this.session.authorization.pipe(
            map((authorization: string | undefined) => {
                const uploader = new FileUploaderCustom({
                    url: [
                        this.session.environment.services,
                        this.endpoint,
                        "bulk",
                    ].join("/"),
                    authToken: authorization,
                });
                uploader.onBuildItemForm = (item: FileItem, form: FormData) => {
                    form.append("owner", owner.asReference.json());
                    form.append("repository", repository.asReference.json());
                    form.append("uploaded_by", uploaded_by.asReference.json());
                    form.append("name", item.file.name ?? "file");
                    if (item.hasOwnProperty("name_alias")) {
                        const aliasedItem = item as DocumentFileItem;
                        if (aliasedItem.name_alias)
                            form.append("alias", aliasedItem.name_alias);
                    }
                    if (item.hasOwnProperty("document_type")) {
                        const aliasedItem = item as DocumentFileItem;
                        if (aliasedItem.document_type)
                            form.append("file_type", aliasedItem.document_type);
                    }
                };
                uploader.onCompleteAll = () => {
                    if (!uploader.cachedLastResponse) return;

                    const json = JSON.parse(uploader.cachedLastResponse);
                    const documents = json.map((doc: any) =>
                        ObjectFactory.makeObject<Document>(doc),
                    );
                    documents.forEach((doc: Document) =>
                        this.objectCreated.next({ object: doc }),
                    );
                    if (onSuccess) onSuccess(documents);
                };
                uploader.onErrorItem = (
                    item: FileItem,
                    response: string,
                    status: number,
                ) => {
                    const err = new HttpErrorResponse({
                        error: response,
                        status: status,
                    });
                    this.session.handleError(err);
                    if (onError) onError(err);
                };
                return uploader;
            }),
        );
    }

    /* Helper function to initiate a secure download of the file represented by document
     *   document: Document object - the document to download
     * */
    download(document: Document | ObjectReference): void {
        const obs: Observable<Document | undefined> =
            document instanceof ObjectReference ?
                this.retrieve(document.id!)
            :   of(document);
        obs.subscribe((doc: Document | undefined) => {
            if (doc) this.session.download(doc.file, doc.alias ?? doc.name);
        });
    }

    upload(data: FormData): Observable<Document | undefined> {
        return this.session
            .restRequest<Document>(
                this.endpoint,
                undefined,
                "post",
                data,
                undefined,
                undefined,
                undefined,
                true,
            )
            .pipe(
                map(
                    (result: any) =>
                        ObjectFactory.makeObject<Document>(result) as
                            | Document
                            | undefined,
                ),
            );
    }

    /* Helper function to copy documents from one repository to another *.
        documents: a list of Document objects
        repository: a DocumentRepository object - the repository to copy the documents to
        return observable: a list of the newly create Document objects
        uploadedBy: The account that is copying the object
     */
    copyDocuments(
        documents: ObjectOrReference<Document>[],
        repository: DocumentRepository,
        uploadedBy?: Account,
    ): Observable<ObjectOrReference<Document>[]> {
        const data = {
            documents: documents.map((doc: ObjectOrReference<Document>) =>
                doc.asReference.serialize(),
            ),
            target: repository.asReference.serialize(),
            uploaded_by: uploadedBy ? uploadedBy.asReference.serialize() : undefined,
        };
        return this.request<Document[]>(
            [this.endpoint, "copy"].join("/"),
            undefined,
            data,
            "post",
        ).pipe(
            map((result: any) =>
                defined(result.map((o: any) => ObjectFactory.makeObject<Document>(o))),
            ),
        );
    }

    /**
     * Invoke the redaction process for a list of documents.
     * @param documentIds Array of document IDs to be reprocessed for redaction.
     * @returns Observable of any (you might want to define a specific type based on your backend response).
     */
    runRedaction(documentIds: string[]): Observable<any> {
        const data = { document_ids: documentIds };
        return this.request<Document[]>(
            [this.endpoint, "redact"].join("/"),
            undefined,
            data,
            "post",
        );
    }

    getRepoRedactionMessage(repoIds: string[]) {
        const data = { repo_ids: repoIds.join(",") };
        return this.request<TabError>(
            [this.endpoint, "repo_redaction_msg"].join("/"),
            undefined,
            data,
            "get",
        ).pipe(map((result) => result as TabError));
    }
}

/*
Assignment Service
    - list, create, retrieve, update, delete operations supported normally
    - available list filters:
        case: a single Case id - will return all assignments associated with the provided case
        assignee: a single Account id - will return all assignments for which the account is the assignee
        completed: boolean - will limit list results to either completed or non-completed assignments
 */
@Injectable()
export class AssignmentService extends APIService<Assignment> {
    constructor() {
        super(Assignment, ["program", "assign"]);
    }

    protected makeObject(
        data: any,
        created?: boolean,
        matchedFilters?: number[] | undefined,
    ): ObjectOrReference<Assignment> {
        const assignment = super.makeObject(data, created, matchedFilters);
        //fix for MED-2712
        if (
            assignment instanceof Assignment &&
            !assignment?.case_name &&
            data?.case_name
        ) {
            assignment.case_name = data?.case_name;
        }

        return assignment;
    }

    remind(
        assignment: ObjectOrReference<Assignment>,
        case_reference: number,
        referenceObject: ObjectOrReference<Inquiry>,
        ownerOrg: ObjectOrReference<Organization>,
    ) {
        const data = {
            assignment: assignment.id,
            reference: referenceObject.asReference.serialize(),
            ownerOrg: ownerOrg?.displayName,
            case_reference,
        };

        return this.request(
            [this.endpoint, "reminder"].join("/"),
            undefined,
            data,
            "post",
        );
    }

    getCaseNames(): Observable<ObjectOrReference<Case>[]> {
        return this.request<ObjectOrReference<Case>[]>(
            [this.endpoint, "get_case_names"].join("/"),
        ).pipe(
            map((result: any) => {
                return (Array.isArray(result) ? result : []).map((o: any) =>
                    ObjectFactory.makeObject<ObjectReference>(o),
                );
            }),
        );
    }

    sendDocusignNow(
        assignment: ObjectOrReference<Assignment>,
        account?: ObjectOrReference<DocusignAccount>,
        resend: boolean = false,
    ): Observable<any> {
        return this.request<any>(
            [this.endpoint, assignment.id, "send_docusign"].join("/"),
            undefined,
            {
                account: account?.asReference,
                resend: resend,
            },
            "post",
        );
    }
}

@Injectable()
export class StatusFactory extends ObjectFactory<Status> {
    constructor() {
        super(Status);
    }
}

@Injectable()
export class TaskFactory extends ObjectFactory<Task> {
    constructor() {
        super(Task);
    }
}

@Injectable()
export class AssignmentReferenceFactory extends ObjectFactory<AssignmentReference> {
    constructor() {
        super(AssignmentReference);
    }
}

@Injectable()
export class AssignmentGroupService extends APIService<AssignmentGroup> {
    constructor() {
        super(AssignmentGroup, ["program", "assign", "assignment_group"]);
    }
}

@Injectable()
export class WorkflowService extends APIService<Workflow> {
    constructor() {
        super(Workflow, ["program", "workflow"]);
    }
}

@Injectable()
export class WorkflowInstanceService extends APIService<WorkflowInstance> {
    constructor() {
        super(WorkflowInstance, ["program", "workflow_instance"]);
    }
}

@Injectable()
export class WorkflowReferenceFactory extends ObjectFactory<WorkflowReference> {
    constructor() {
        super(WorkflowReference);
    }
}

/* Patient Service
    This is a helper service, which will get any cases or inquiries that match the results
*/
@Injectable()
export class PatientService extends APIService<Patient> {
    constructor() {
        super(Patient, ["program", "patient"]);
    }

    getAccountsByKey(
        key: "physicians" | "owners",
    ): Observable<ObjectOrReference<Account>[]> {
        const data = { key };
        return this.request<ObjectOrReference<Account>[]>(
            [this.endpoint, "accounts_by_key"].join("/"),
            undefined,
            data,
            "get",
        ).pipe(
            map((result: any) =>
                result.accounts.map((o: any) =>
                    ObjectFactory.makeObject<ObjectReference>(o),
                ),
            ),
        );
    }
    physicianOrgs() {
        return this.request<any>([this.endpoint, "physician_orgs"].join("/")).pipe(
            map((result: any) =>
                result.organizations.map((o: any) =>
                    ObjectFactory.makeObject<ObjectReference>(o),
                ),
            ),
        );
    }
}

/* Team Service
    This is a helper service, which will get any cases or inquiries that match the results
*/
@Injectable()
export class TeamService extends APIService<Team> {
    constructor() {
        super(Team, ["program", "team"]);
    }
}

@Injectable()
export class CaseTeamService extends APIService<CaseTeam> {
    constructor() {
        super(CaseTeam, ["program", "case_team"]);
    }

    getCaseTeams(inquiry: ObjectOrReference<Inquiry>, userCapacity: string) {
        const data = {
            inquiry_id: inquiry.id,
            user_capacity: userCapacity,
        };
        return this.request<CaseTeam[]>(
            [this.endpoint, "get_case_teams"].join("/"),
            undefined,
            data,
        ).pipe(
            map((result: any) =>
                result.map((o: any) => ObjectFactory.makeObject<CaseTeam>(o)),
            ),
        );
    }
}

@Injectable()
export class TeamMemberService extends APIService<TeamMember> {
    constructor() {
        super(TeamMember, ["program", "member"]);
    }
    getMembersForInquiries(
        inquiry_ids: string[],
        case_ids: string[],
        org_ids: string[],
    ) {
        const data = {
            inquiry_ids,
            case_ids,
            org_ids,
        };

        return this.request<string[]>(
            [this.endpoint, "members_for_objects"].join("/"),
            undefined,
            data,
        ).pipe(map((result: any) => result["emails"] as string[]));
    }

    getMemberEmails(ownerIds: string[]) {
        const validOwnerIds = ownerIds.filter((id) => isValidUUID(id));

        if (validOwnerIds.length === 0) {
            throw new Error("No valid UUIDs provided.");
        }

        const data = {
            owner: validOwnerIds.join(","),
        };

        return this.request<string[]>(
            [this.endpoint, "get_member_emails"].join("/"),
            undefined,
            data,
        ).pipe(map((result: any) => result["emails"] as string[]));
    }
}

@Injectable()
export class DiscussionService extends APIService<DiscussionEntry> {
    constructor() {
        super(DiscussionEntry, ["program", "discussion"]);
    }
    isUserMentioned(repo_ids: string): Observable<boolean> {
        const data = {
            ids: repo_ids,
        };
        return this.request(
            [this.endpoint, "is_user_mentioned"].join("/"),
            undefined,
            data,
            "get",
        ).pipe(map((result: any) => !!result?.mentioned));
    }
}

@Injectable()
export class ProductService extends APIService<Product> {
    constructor() {
        super(Product, ["program", "product"]);
    }
}

@Injectable()
export class CountryService extends APIService<Country> {
    countries: Country[] = [];
    countriesLatLongDict: CountryLatLong = {}; // Required to support legacy code in dashboard.component.ts
    public fallbackFlagUrl =
        "https://upload.wikimedia.org/wikipedia/commons/2/2a/Flag_of_None.svg";

    constructor() {
        super(Country, ["program", "country"]);
    }

    getCountries(forceRefresh: boolean = false): Observable<Country[]> {
        if (this.countries.length > 0 && !forceRefresh) {
            return of(this.countries); // Return an observable of the already populated countries
        }

        return this.list().pipe(
            map((countries) => countries as Country[]),
            // Build countriesLatLongDict
            map((countries) => {
                countries.forEach((country) => {
                    this.countriesLatLongDict[country.value] = {
                        latitude: country.latitude!,
                        longitude: country.longitude!,
                    };
                });
                return countries;
            }),
            map((countries) => {
                countries.sort((a, b) => a.display_name.localeCompare(b.display_name));
                this.countries = countries;
                return countries;
            }),
        );
    }
}

@Injectable()
export class ProgramCountryService extends APIService<ProgramCountry> {
    constructor() {
        super(ProgramCountry, ["program", "program_country"]);
    }
}
