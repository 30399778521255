<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
>
    <div class="wrapper flex stack flexible" *ngIf="mode == ObjectViewMode.Create">
        <mat-form-field class="full margin-top">
            <mat-label>Account</mat-label>
            <mat-select
                formControlName="account"
                (selectionChange)="accountChangeHandler($event.value)"
            >
                <mat-option
                    *ngFor="let teamMember of availableStaff"
                    [value]="teamMember.account"
                >
                    {{ teamMember.account.displayName }}
                    <span class="small option-spacer">{{ teamMember.email }}</span>
                </mat-option>
                <mat-option value="newUser">New User...</mat-option>
            </mat-select>
            <mat-hint>
                Add an existing user from another program or country, or create a new
                user.
            </mat-hint>
        </mat-form-field>
        <ng-container *ngIf="showNewUserControls">
            <div class="flex row">
                <mat-form-field appearance="outline" class="column input">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="first_name" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="column input">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="last_name" />
                </mat-form-field>
            </div>
            <mat-form-field appearance="outline" class="full input">
                <mat-label>Email Address</mat-label>
                <input matInput formControlName="email" />
                <mat-error *ngIf="emailControl?.errors?.emailExists">
                    This email already exists.
                </mat-error>
                <mat-error *ngIf="emailControl?.errors?.required">
                    Please enter an email address.
                </mat-error>
                <mat-error *ngIf="emailControl?.errors?.email">
                    Please enter a valid email address.
                </mat-error>
            </mat-form-field>
            <mat-form-field
                *ngIf="capabilitiesList$ | async as capabilities"
                class="full"
            >
                <mat-label>Role</mat-label>
                <mat-select formControlName="capabilities">
                    <mat-option
                        *ngFor="let capability of capabilities"
                        [value]="capability"
                    >
                        {{ capability.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </div>
    <div *ngIf="mode == ObjectViewMode.Edit">
        <div class="margin-top">{{ fullObject?.email }}</div>
        <mat-form-field
            *ngIf="capabilitiesList$ | async as capabilities"
            class="full margin-top"
        >
            <mat-label>Role</mat-label>
            <mat-select formControlName="capabilities">
                <mat-option
                    *ngFor="let capability of capabilities"
                    [value]="capability"
                >
                    {{ capability.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div
        class="wrapper flex stack flexible"
        *ngIf="mode == ObjectViewMode.Create || ObjectViewMode.Edit"
    >
        <mat-form-field class="full margin-top">
            <mat-label>Permission</mat-label>
            <mat-select formControlName="permission" placeholder="Select a Permission">
                <mat-option
                    *ngFor="let role of availableRoles"
                    [disabled]="
                        mode == ObjectViewMode.Create && role.value === 'object.none'
                    "
                    [value]="role"
                >
                    {{ role.display }}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="currentAccount?.id == fullObject?.account?.id">
                You cannot modify your own permission.
            </mat-hint>
        </mat-form-field>
        <mat-checkbox
            [checked]="!formGroup.get('private')!.value"
            (change)="formGroup.get('private')!.setValue(!$event.checked)"
            [matTooltip]="publicCheckboxToolTip"
        >
            <mat-label>Public</mat-label>
        </mat-checkbox>
    </div>
</object-form>
