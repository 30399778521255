import { APIObject, ObjectReference } from "./api-object";

export class AccountEmail {
    account?: ObjectReference;
    email?: string;

    constructor(data: any) {
        if (data?.hasOwnProperty("account"))
            this.account = new ObjectReference(data["account"]);
        if (data?.hasOwnProperty("email")) this.email = data["email"];
    }

    get displayName(): string {
        let ret = this.account?.displayName;
        if (ret && this.email) return ret + " (" + this.email + ")";
        return this.email ?? "";
    }
}
export class Message extends APIObject {
    static object_type: string = "notifications.message";
    sender!: AccountEmail; // Account or email address
    targets!: AccountEmail[]; // Account or email address
    reference?: ObjectReference; // optional
    subject!: string;
    message!: string;
    template?: ObjectReference; // Template, write-only
    context?: any; // write-only
    sent?: Date;
    attachments!: ObjectReference[];
    send_invite?: ObjectReference; // Sender Account
    in_bulk?: boolean;
    with_pharma_staff_only!: boolean;
    initialize(data?: any, patch: boolean = false) {
        this._optional.push(
            ...["template", "context", "reference", "send_invite", "in_bulk"],
        );
        super.initialize(data, patch);
        this.setMember(data, patch, "reference", ObjectReference);
        this.setMember(data, patch, "send_invite", ObjectReference);
        this.setMember(data, patch, "in_bulk");
        this.setMember(data, patch, "subject");
        this.setMember(data, patch, "message");
        this.setMember(data, patch, "template", ObjectReference);
        this.setMember(data, patch, "context");
        this.setMember(data, patch, "sent", Date);
        this.setMember(data, patch, "attachments", ObjectReference, true);
        this.setMember(data, patch, "sender", AccountEmail);
        this.setMember(data, patch, "targets", AccountEmail, true);
        this.setMember(data, patch, "with_pharma_staff_only");
    }

    get target(): AccountEmail {
        return this.targets[0];
    }
}
