<object-form
    class="wrapper flex flexible"
    [controller]="controller"
    [formGroup]="formGroup"
    [hideControls]="true"
>
    <mat-tab-group>
        <mat-tab label="Program Details" *ngIf="isProgramAdministrator">
            <ng-template matTabContent>
                <div class="content-card content-container" *ngIf="organization?.id">
                    <program-details
                        [object]="object"
                        [mode]="ObjectViewMode.Edit"
                        [organization]="organization"
                        [autosave]="true"
                    ></program-details>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Team" *ngIf="isProgramAdministrator">
            <ng-template matTabContent>
                <div class="content-container">
                    <staff-list
                        [isHcpStaff]="isHcpStaff"
                        [teamOwner]="fullObject"
                    ></staff-list>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Documents" *ngIf="isProgramAdministrator">
            <ng-template mat-tab-label>
                <mat-icon
                    *ngIf="!!tabErrors['Documents']"
                    class="fixed"
                    [ngClass]="tabErrors['Documents'].severity"
                    [matTooltip]="tabErrors['Documents'].message"
                >
                    warning
                </mat-icon>
                Documents
            </ng-template>
            <div class="content-card content-container">
                <document-repository
                    [repository]="object"
                    [owner]="organization"
                    [caseOrg]="organization"
                    [canAdd]="true"
                    (setCaseError)="setTabError('Documents', $event)"
                    [redactionEnabled]="redactionEnabled"
                ></document-repository>
            </div>
        </mat-tab>
        <mat-tab label="Templates" *ngIf="isProgramAdministrator">
            <ng-template matTabContent>
                <div class="content-container">
                    <template-list
                        [owner]="fullObject"
                        [others]="[fullObject?.organization]"
                        [showTypes]="true"
                        [inCard]="true"
                        headerTitle="Program Templates"
                    ></template-list>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Forms" *ngIf="canUseFormBuilder && isProgramAdministrator">
            <ng-template matTabContent>
                <div class="content-container">
                    <data-admin [repository]="this.fullObject!"></data-admin>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab
            label="Countries"
            *ngIf="isProgramAdministrator || isProgramCountryAdministrator"
        >
            <ng-template matTabContent>
                <div class="content-container">
                    <program-country-admin
                        [program]="fullObject"
                    ></program-country-admin>
                </div>
            </ng-template>
        </mat-tab>
        <!--Workflow builder is not implemented yet
    <mat-tab label="Workflows">
        <ng-template matTabContent>
            <div class="content-container"></div>
        </ng-template>
    </mat-tab>-->
    </mat-tab-group>
    <mat-dialog-actions form-actions class="row flex object-actions">
        <div class="spacer"></div>
        <button
            mat-flat-button
            color="primary"
            class="fixed"
            type="button"
            mat-dialog-close
        >
            Close
        </button>
    </mat-dialog-actions>
</object-form>
